import {
  Annotations, GameAction, GameFull, GameQAStatus, Moment, Team, User,
} from 'weplayed-typescript-api';

import {
  AnnotationsCanvas, AnnotationsPanel,
} from 'common/components/Annotations';

import {
  MomentActionsProviderRefType,
} from 'cms/components/MomentActions/types';
import { Tag } from 'cms/components/TagInput/types';

export enum MODE {
  NONE = 0,
  EDIT,
  ANNOTATIONS,
}

export interface UrlParams {
  videoId?: string;
  loopFrom?: number;
  loopTo?: number;
}

export interface UrlProps {
  uid: string;
  momentId?: string;
}

export enum FilterTagType {
  TEXT,
  CONTROL,
  PLAYER,
  TEAM,
  TAG,
}

export enum FilterControlTags {
  REVIEWED = 'Reviewed',
  NOT_REVIEWED = 'Not reviewed',
  MY = 'My moments',
  PLAY_BY_PLAY = 'Play by Play',
}

export interface FilterTag extends Tag {
  color?: string;
  pk?: string;
  type: FilterTagType;
}

export type HubEntry = [GameAction?, Moment?];

export type HubEntries = Array<{
  name: string;
  short: string;
  entries: HubEntry[];
}>;

export interface GameProviderContextType extends UrlProps, UrlParams {
  annotations: Annotations,
  annotationsCanvas: React.MutableRefObject<AnnotationsCanvas>;
  annotationsPanel: React.MutableRefObject<AnnotationsPanel>;
  momentsAction: React.MutableRefObject<MomentActionsProviderRefType>;
  atLiveEdge: boolean;
  cms: string;
  duration: number;
  filters: FilterTag[];
  findMoment(uid: string): Moment | undefined;
  game: GameFull;
  highlighted?: Moment[];
  hub: HubEntries;
  loaded: boolean;
  loading: boolean;
  mode: MODE;
  moment: Moment | null;
  moments: Moment[] | null;
  onAnnotations(annotations?: Annotations): void;
  onCMS(cms?: string): void;
  onCreateMoment(position?: number, text?: string, pbp?: number): void;
  onCreatePlaylist(): void;
  onEditMoment(uid?: string);
  onEndStream(): void;
  onFilters(tags: FilterTag[]): void;
  onFocusMoments(moments?: Moment[]): void;
  onJumpTo(moment: Moment): void;
  onMoment(moment: Moment): void;
  onMomentAnnotateStart(): void;
  onMomentAnnotateEnd(commit?: boolean): void;
  onMomentDelete(moment: Moment): void;
  onMomentCommit(): void;
  onMomentSelect(moment: Moment): void;
  onPosition(position: number, duration?: number, atLiveEdge?: boolean);
  onQA(status: GameQAStatus): void;
  onState(playing: boolean): void;
  positionTo(position: number);
  recentTeam?: Team;
  requestedPosition?: number;
  requestedState?: boolean;
  saving: boolean;
  segments: Array<{ time: number; name: string }>;
  state: boolean;
  stateTo(playing: boolean): void;
  suggestions: FilterTag[];
  toReview: Moment[];
  user: User;
}
