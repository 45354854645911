// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "f869793ff0d79d40619c8f9f5ddb7422-less";
export var backgroundGrayGradient = "_201f1dc4a6ce682fde7ecfd86854a847-less";
export var tileImage = "ac0dd7d5e76b5bd3b0bb04a33b9afaa3-less";
export var small = "d706178e1c75562a99612b59d7018eeb-less";
export var root = "_4ff83b0f1e94bb0acc2453ad513e45bd-less";
export var animated = "_23dd83e6779e061f6842ab040a6a1877-less";
export var hover = "b5d3972e3cfb7cff0a33cea1a2b7799a-less";
export var fadeIn = "bbe76bd3c5a1d4dfc26533c833245294-less";
export var area = "fa9b39f32b8266f11bff27fc928957b9-less";
export var spin = "_788c4a954ae3bff9ea477784abdbc9c3-less";