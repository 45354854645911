import { noop } from 'lodash/fp';
import * as React from 'react';
import { What } from 'weplayed-typescript-api';

import { UseUploadsReturnType } from 'cms/hooks/useUploads/types';
import { GameBasic } from 'cms/types';

import { AdminContextType } from './types';

export const AdminContext = React.createContext<AdminContextType<What>>(
  {} as AdminContextType<What>,
);

export const UploadsContext = React.createContext<UseUploadsReturnType<GameBasic>>({
  abort: noop,
  active: 0,
  append: noop,
  clear: noop,
  resume: noop,
  state: {},
  stats: {
    digest: 0,
    error: 0,
    upload: 0,
  },
});
