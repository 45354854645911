// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_2d1dae5ee4699a7d25ce9fa1406f9b07-less";
export var backgroundGrayGradient = "b92e14bb70c74a785bdfac88c93e67ed-less";
export var tileImage = "_35ff3bb3333382726e72324b1fcd4802-less";
export var small = "_4953a72f6dd6b92eeab97453d81eba95-less";
export var root = "_708c5e00a0551be0bc32f55c5c8039e4-less";
export var global = "_9bfea0778d47492a459d0951322f111c-less";
export var menu = "_46200fdf16dd8e7e522e4be13a6b8742-less";
export var item = "cb348bda46b754145097f6e91b34ae69-less";
export var itemWithDescription = "_44a43907301dc1d9c3f428405c0b12ac-less";
export var form = "_19d90f464ae3931f1c92e0127346fde8-less";
export var fadeIn = "_15ea80b6e1ba5759925d218ef76a1fe2-less";
export var spin = "e5a8c9e2e8c51a7f71b5c4ba68d498f1-less";