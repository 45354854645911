import * as React from 'react';

import { loadCSSs, loadJSs } from 'common/utils/loaders';
import { ensureVariable } from 'common/utils/variables';

import {
  VIDEOJS_ADS_CSS_URLS, VIDEOJS_ADS_JS_URLS, VIDEOJS_JS_URLS,
} from './constants';
import { VideoPlayerProps } from './types';
import { testReady } from './utils';
import { VideoPlayer } from './VideoPlayer';

const placeholder = <div><div style={{ paddingTop: `${(900 / 16).toFixed(2)}%` }} /></div>;

/**
 * This component do few things:
 * 1. load videojs components including ads-related
 * 2. reinitialize video component after wake from sleep
 */
export const withPlayerGuard: (
  Component: React.ComponentClass<VideoPlayerProps>
) => React.ForwardRefRenderFunction<
  VideoPlayer,
  VideoPlayerProps
> = (
  Component: React.ComponentClass<VideoPlayerProps>,
) => function VideoPlayerGuard({
  adTagUrl,
  ...props
}, ref): JSX.Element {
  const adTag = React.useRef<string>(adTagUrl);

  const [ready, setReady] = React.useState(() => testReady(adTagUrl));

  const [serial, bump] = React.useReducer((s) => s + 1, 0);

  React.useEffect(() => {
    const pageshowHandler = ({ persisted }: PageTransitionEvent): void => persisted && bump();

    window.addEventListener('pageshow', pageshowHandler);

    return (): void => {
      window.removeEventListener('pageshow', pageshowHandler);
    };
  }, [serial]);

  const timer = React.useRef<number>();

  React.useEffect(() => {
    if (!ready) {
      let mounted = true;

      (async (): Promise<void> => {
        try {
          // we do not load videojs css file because it is really not necessary,
          // all required css changes are already covered with custom styles
          await loadJSs(VIDEOJS_JS_URLS);

          // sometimes google chrome reports that script is loaded (from cache) when
          // actually it wasn't yet evaluated so we need to wait for variable
          await ensureVariable('videojs');
        } catch (e) {
          // cannot load js/css
          return;
        }

        // load ads related js and css, disable ads if failed (adblock test)
        if (adTag) {
          try {
            await Promise.all([
              loadJSs(VIDEOJS_ADS_JS_URLS),
              loadCSSs(VIDEOJS_ADS_CSS_URLS),
            ]);
          } catch (e) {
            adTag.current = null;
          }
        }

        timer.current = window.setInterval(() => {
          if (testReady(adTag.current)) {
            window.clearInterval(timer.current);

            if (mounted) {
              setReady(true);
            }
          }
        }, 100);
      })();

      return (): void => {
        mounted = false;
        window.clearInterval(timer.current);
      };
    }
  }, [ready]);

  return ready
    ? <Component key={serial} ref={ref} adTagUrl={adTag.current} {...props} />
    : placeholder;
};
