import {
  ID, profile, SportSlug, TeamBase, What,
} from 'weplayed-typescript-api';

type Tuple<T> = [T, T];

export type StringTuple = Tuple<string>;

export interface TeamSearch extends TeamBase {
  sport?: SportSlug;
}

export interface BaseAction<T> {
  action: T;
  fail?: string;
  notify?: boolean;
  success?: string;
}

export interface WithLike {
  like: boolean;
}

export type UpdateUser = profile.update.Params;

/**
 * Available sizes
 */
export type Size = 'xx-small' | 'x-small' | 'small' | 'normal' | 'large' | 'x-large';

export type SocialTarget = 'facebook' | 'twitter' | 'linkedin';
export type ShareTarget = SocialTarget | 'email' | 'copy' | 'native';

export enum SECTION {
  GAMES = 'games',
  COLLECTIONS = 'collections',
  MOMENTS = 'moments',
  PLAYERS = 'players',
}

export enum LibraryTypes {
  FAVORITES = 'favorites',
  CREATED = 'created',
}

export interface EmbedParams {
  /**
   * Play ads in embed player
   * @default true
   */
  ads: boolean;

  /**
   * Try to autoplay video
   * @default false
   */
  autoplay: boolean;

  /**
   * Collection ID to play
   * @default '''
   */
  collectionId: ID;

  /**
   * Disable links to external resources
   * @default false
   */
  disableLinks: boolean;

  /**
   * Misc features passed from outer environment
   */
  features: Record<string, unknown>;

  /**
   * Display moment infoframe
   * @default false
   */
  infoframe: boolean;

  /**
   * List of moments to play
   * @default []
   */
  moments: ID[];

  /**
   * Org ID
   * @default ''
   */
  orgId: ID;

  /**
   * ???
   */
  parent: string;

  /**
   * Team number communicated for ad serving purposes,
   * set to either 1 or 2 to serve ads for specific team
   * @default ''
   */
  t: string;

  /**
   * Render static image with play button instead of VideoPlayer
   * @default false
   */
  thumb: boolean;

}

export enum CollectionMomentsSortOrder {
  PLAY_ASC = 'By Play: First to Last',
  PLAY_DESC = 'By Play: Last to First',
  DATE_DESC = 'Date added (newest)',
  DATE_ASC = 'Date added (oldest)'
}

export enum GameMomentsSortOrder {
  'start' = 'By Play: First to Last',
  '-start' = 'By Play: Last to First',
  '-ctime' = 'Date added (newest)',
  'ctime' = 'Date added (oldest)'
}

export interface ShareMeta {
  meta: ShareData;
  type: What;
  pk: ID;
}

/**
 * User settings interface
 */
export interface Settings {
  /**
   * Toggle moment scores visibility
   * @default false
   */
  game_moment_scores: boolean;

  /**
   * Toggle moment status visibility
   * @default false
   */
  game_moment_status: boolean;

  /**
   * Toggle recent activity collapse state on home screen
   * @default true
   */
  recent_activity_visible: boolean;

  /**
   * Send email when download export is done
   * @default true
   */
  send_download_email: boolean;

  /**
   * Show base moment edit warning for staff users
   * @default true
   */
  staff_edit_warning: boolean;

  /**
   * Settings for different list view tyoes like search results, discovery etc
   */
  entities?: Record<string, Record<string, unknown>>;
}

/**
 * Available settings keys
 */
export type SessionSettings = Array<keyof Settings>;

/**
 * Type of crop box entity
 */
export interface CropBox {
  /**
   * Crop box height in range 0..1,
   * relative to source video frame height
   */
  height: number;

  /**
   * Crop box width in range 0..1,
   * relative to source video frame width
   */
  width: number;

  /**
   * Crop box center X coordinate, in range of 0..1,
   * relative to source video frame width
   */
  x: number;

  /**
   * Crop box center Y coordinate, in range 0..1,
   * relative to source video frame height
   */
  y: number;
}
