import { noop } from 'lodash/fp';
import * as React from 'react';

import {
  ActionType, MomentActionsContextType, MomentActionsStatus,
} from './types';

export const defaultState: MomentActionsStatus = {
  clone: [],
  collections: [],
  create: [],
  delete: [],
  flag: [],
  hide: [],
  pin: [],
  publish: [],
  remove: [],
  review: [],
  update: [],
};

export const MomentActionsContext = React.createContext<MomentActionsContextType>({
  invoke: noop,
  user: null,
  status: defaultState,
});

export const AuthRequired: ActionType[] = [
  'adjust',
  'bulk_delete',
  'bulk_download',
  'bulk_like',
  'clone',
  'collections',
  'delete',
  'download',
  'edit',
  'flag',
  'hide',
  'like',
  'pin',
  'publish',
  'remove',
  'review',
];
