type JSSource = string | {
  id?: string;
  url: string;
}

const loadJS = async (url: JSSource): Promise<void> => {
  await new Promise((ok, ko) => {
    const script = document.createElement('script');
    script.onload = ok;
    script.onerror = ko;
    script.type = 'text/javascript';
    document.head.appendChild(script);
    if (typeof url === 'string') {
      script.src = url;
    } else {
      script.src = url.url;
      script.id = url.id;
    }
  });
};

/**
 * Loads JS files into browser namespace in parallel
 * @param urls List of URLs to load
 */
export const loadJSs = async (urls: JSSource[]): Promise<void> => {
  const scripts = Array.from(document.getElementsByTagName('script'))
    .map((script) => script.src)
    .filter((src) => urls.includes(src));

  await Promise.all(
    urls.filter((url) => !scripts.includes(typeof url === 'string' ? url : url.url)).map(loadJS),
  );
};

const loadCSS = async (url: string): Promise<void> => {
  await new Promise((ok, ko) => {
    const link = document.createElement('link');
    link.onload = ok;
    link.onerror = ko;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);
    link.href = url;
  });
};

/**
 * Loads CSS files into browser namespace in parallel
 * @param urls List of URLs to load
 */
export const loadCSSs = async (urls: string[]): Promise<void> => {
  const csss = Array.from(document.getElementsByTagName('link'))
    .map((css) => css.href)
    .filter((css) => urls.includes(css));

  await Promise.all(urls.filter((url) => !csss.includes(url)).map(loadCSS));
};
