import * as download from './download.png';
import * as s from './ExportsMenu.m.less';
import * as taskbar from './taskbar.png';

export const BlockingWarning = (
  <>
    Your browser prevents the opening of multiple tabs with videos.
    <br />
    Please allow opening new windows by clicking on the blocking icon
    in the taskbar:
    <img src={taskbar} className={s.img} alt="Allow opening new windows" />
    You can always download all the prepared moments and collections
    manually in the Download Menu:
    <img src={download} className={s.img} alt="Download Menu button" />
  </>
);
