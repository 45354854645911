import { LocationDescriptor } from 'history';
import {
  BaseEntityWithPk, Collection, Game, ID, Moment, Player,
} from 'weplayed-typescript-api';

import { DragData } from 'common/utils/drag';

import { CollectionActionStatus } from '../CollectionActions/types';
import { MomentActionsStatus } from '../MomentActions/types';

/**
 * Available item sizes, `LARGE` is the default
 */
export enum EntitiesSize {
  /**
   * Full size items
   */
  LARGE = 'large',

  /**
   * Items of reduced size and functionality. Actual size
   * change is controlled by CSS
   */
  COMPACT = 'compact',
}

/**
 * Possible list types
 */
export enum EntitiesListType {
  /**
   * Table-based list
   */
  LIST = 'list',

  /**
   * Tile-based list
   */
  TILE = 'tile',
}

type LinkType = false | LocationDescriptor;

export type LinkGetterType<
  T extends BaseEntityWithPk
> = false | ((item: T) => LinkType);

export interface EntityLinkProps {
  /**
   * Additional CSS class name to the link element
   */
  className?: string;

  /**
   * Generated URL to render
   */
  url?: false | LocationDescriptor;
}

/**
 * Base control props, like follow, copy link etc
 */
export interface BaseControlsProps {
  /**
   * Additional CSS class name for control
   */
  className?: string;

  /**
   * Disable control
   */
  disabled?: boolean;

  /**
   * Hide control
   */
  hidden?: boolean;

  /**
   * Click handler
   */
  onClick?: React.MouseEventHandler;
}

/**
 * Base item component props
 */
export interface BaseItemProps<
  T extends BaseEntityWithPk,
> {
  /**
   * Specifies if we need to provide to user an ability to select item.
   * If node is provided, it will be used to render instead of simple
   * `<input type="checkbox" />`. In this case selected state needs to
   * be reflected by component itself.
   * Additionally, `selected` property may be set as well to any boolean value
   * to show more visual feedback to the user (and enable whole item mode
   * for selection)
   */
  checkbox?: React.ReactElement;

  /**
   * Inner item content
   */
  children?: React.ReactNode;

  /**
   * Additional CSS class name for the item root element
   */
  className?: string;

  /**
   * Extra content related to item to display
   */
  content?: React.ReactNode;

  /**
   * A list of controls to render. All these controls will be rendered
   * in provided order in the special places, top bar for tiles and
   * right part of table in lists
   */
  controls?: React.ReactElement<BaseControlsProps>[];

  /**
   * Disable item from interactions, also the item will be grayed out to
   * let the user know current status
   */
  disabled?: boolean;

  /**
   * This flag is used to hide source drag item during dragging
   * since removing it from DOM will lead to missed dragend event.
   * Hiding item should never remove item from DOM!
   */
  hidden?: boolean;

  /**
   * The way to highlight the item in the list
   */
  highlighted?: boolean;

  /**
   * Current item index in the set, can be used to highlight even/odd
   * entries in the table view
   */
  index: number;

  /**
   * Actual item to render
   */
  item: T;

  /**
   * Entity body click event listener
   */
  onClick?: React.MouseEventHandler;

  /**
   * Flag to know when item is selected. If provided, item switches
   * to `check only` mode when no other interactions are available
   * except toggling checkbox state (whole item becomes a toggle button)
   */
  selected?: boolean;

  /**
   * The size of list, can be used to reduce functionality/texts inside
   * items
   */
  size?: EntitiesSize;
}

/**
 * Advanced item properties, at the moment all exposed components
 * support these.
 * In some cases, incoming data may have nested structure where
 * content for the rendering sits somewhere deep in the structure,
 * but the `pk` property is on top level. For this case generic accepts
 * first parameter as a type for incoming data, and to be able to render
 * data correctly `getData` function (data extractor from incoming
 * format) must be provided.
 */
export type AdvancedItemProps<
  T extends BaseEntityWithPk,
  D extends BaseEntityWithPk = T,
  E extends T = T
> = {
  /**
   * `onClick` listener for like control
   */
  onLike?: React.MouseEventHandler;

  /**
   * `onClick` listener for link control
   */
  onLink?: React.MouseEventHandler;

  /**
   * `onClick` listener for menu control
   */
  onMenu?: React.MouseEventHandler;
} & (
  T extends D
    ? { getData?(item: E): D; }
    : { getData(item: E): D; }
);

/**
 * Properties for more functional components which
 * supports blocking and pinning
 */
type PublishableItemProps<
  T extends BaseEntityWithPk,
  D extends BaseEntityWithPk = T
> = AdvancedItemProps<T, D> & {
  /**
   * Block loading state
   */
  blocking?: boolean;

  /**
   * `onClick` event listener for block control
   */
  onBlock?: React.MouseEventHandler;

  /**
   * `onClick` listener for pin control
   */
  onPin?: React.MouseEventHandler;

  /**
   * Pinning loading state
   */
  pinning?: boolean;
}

/**
 * Collection item extra props
 */
export interface CollectionItemExtraProps<
  D extends Collection
> {
  /**
   * Display dedicated athlete information
   */
  athlete?: boolean;

  /**
   * Display date when collection has been created
   */
  created?: boolean;

  /**
   * Display collection creator information
   */
  creator?: boolean;

  /**
   * Display date when collection has been edited last time
   */
  edited?: boolean;

  /**
   * Display dedicated game information
   */
  game?: boolean;

  /**
   * Link generator
   */
  link?: LinkGetterType<D>;

  /**
   * Display position information
   */
  position?: number;

  /**
   * Display sport information
   */
  sport?: boolean;

  /**
   * Display `private` label for private collections
   */
  visibility?: boolean;
}

export type CollectionItemProps<
  T extends BaseEntityWithPk = Collection,
  D extends Collection = Collection,
> = BaseItemProps<T> & CollectionItemExtraProps<D> & PublishableItemProps<T, D>;

/**
 * Game item extra props
 */
export interface GameItemExtraProps<
  D extends Game
> {
  /**
   * Hide the `live/processing` label
   */
  hideLabel?: boolean;

  /**
   * Link generator
   */
  link?: LinkGetterType<D>;
}

export type GameItemProps<
  T extends BaseEntityWithPk = Game,
  D extends Game = Game,
> = BaseItemProps<T> & GameItemExtraProps<D> & AdvancedItemProps<T, D>;

/**
 * Moment item extra props
 */
export interface MomentItemExtraProps<
  D extends Moment
> {
  /**
   * Display the `base moment` label
   */
  base?: boolean;

  /**
   * Display create date
   */
  created?: boolean;

  /**
   * Display creator info
   */
  creator?: boolean;

  /**
   * Display last edited date
   */
  edited?: boolean;

  /**
   * Display the game info
   */
  game?: boolean;

  /**
   * Link generator
   */
  link?: LinkGetterType<D>;

  /**
   * Display position number
   */
  position?: number;

  /**
   * Display sport information
   */
  sport?: boolean;
}

export type MomentItemProps<
  T extends BaseEntityWithPk = Moment,
  D extends Moment = Moment,
> = BaseItemProps<T> & MomentItemExtraProps<D> & PublishableItemProps<T, D>;

/**
 * Interface used to store list settings into external storage
 */
export interface EntitiesListConfig {
  /**
   * List type
   */
  type: EntitiesListType;

  /**
   * Entities size
   */
  size: EntitiesSize;

  /**
   * For future use
   */
  [K: string]: unknown;
}

/**
 * Player item extra props
 */
export interface PlayerItemExtraProps<
  D extends Player
> {
  /**
   * Link generator
   */
  link?: LinkGetterType<D>;

  /**
   * Display sport information
   */
  sport?: boolean;
}

export type PlayerItemProps<
  T extends BaseEntityWithPk = Player,
  D extends Player = Player,
> = BaseItemProps<T> & PlayerItemExtraProps<D> & AdvancedItemProps<T, D>;

/**
 * Entity list context
 */
export interface EntitiesListContextType<
  T extends BaseEntityWithPk
> {
  /**
   * Custom checkbox generator
   * @param item Item
   */
  checkbox?(item: T): React.ReactElement;

  /**
   * List config
   */
  config: EntitiesListConfig;

  /**
   * `Content` section generator
   */
  content?(item: T, onClose: () => void): React.ReactNode;

  /**
   * List of disabled items
   */
  disabled?: boolean | ((item: T) => boolean);

  /**
   * ID of dragged item
   */
  dragData?: DragData;

  /**
   * The dragging item index in the current list
   * In case of dragging item from another list it is always -1
   */
  dragIndex: number;

  /**
   * Current drag position, should never be -1 when dragging over current list
   */
  dragPosition: number;

  /**
   * Drop allowed flag
   */
  dropAllowed?: boolean;

  /**
   * Item with the currently expanded `content` section
   */
  expanded?: ID;

  /**
   * Item locator by event
   * @param e Event passed by items
   */
  findItem(e: React.MouseEvent | React.KeyboardEvent | React.TouchEvent | React.ChangeEvent): T;

  /**
   * `onClick` item event listener
   */
  handleClick?: React.MouseEventHandler;

  /**
   * Container `onDragStart`/`onDragEnd` event listener
   */
  handleContainerDrag?: React.DragEventHandler;

  /**
   * Container `onDrop`/`onDragEnter`/`onDragLeave`/`onDragOver` event listener
   */
  handleContainerDrop?: React.DragEventHandler;

  /**
   * Listener for keyboard events, specifically tracks `Enter` keypress to
   * intercept clicks using keyboard
   */
  handleEnter?: React.KeyboardEventHandler;

  /**
   * Mouse event handler to toggle `more` block
   */
  handleExpand?: React.MouseEventHandler;

  /**
   * Item selection event listener
   */
  handleSelect?: React.MouseEventHandler;

  /**
   * Test for section expand availability
   * @param item Item
   */
  hasExpand?(item: T): boolean | React.ReactNode;

  /**
   * A list of highlighted items
   */
  highlighted?: T | T[];

  /**
   * Total list of items
   */
  items: T[];

  /**
   * Link generator
   */
  link?: LinkGetterType<T>;

  /**
   * Maximum number of items in list allowed, used to hide all the items
   * while dropping
   */
  max?: number;

  /**
   * A list of selected items
   */
  selected?: T[];

  /**
   * Expanded item setter
   */
  setExpanded(item?: T): void;

  /**
   * Type of items
   */
  what?: string;
}

/**
 * Group context type. This context is necessary to use dynamic
 * type/size switching and selection
 */
export interface EntitiesListGroupContextType<
  T extends BaseEntityWithPk
> {
  /**
   * List config
   */
  config: EntitiesListConfig;

  onConfig?(config: DeepPartial<EntitiesListConfig>);

  /**
   * Callback for changing selection
   * @param item
   */
  onSelect?(item: T): void;

  /**
   * List of selected items
   */
  selected?: T[];
}

/**
 * Entities group container props
 */
export interface EntitiesListGroupProps<
  T extends BaseEntityWithPk
> {
  /**
   * Callback for changing selection
   * @param item
   */
  onSelect?(item: T): void;

  /**
   * A list of selected items
   */
  selected?: T[];

  /**
   * Setting name to store setting to
   */
  setting?: string;

  /**
   * Initial entities size
   */
  size?: EntitiesSize;

  /**
   * Initial entities type
   */
  type?: EntitiesListType;
}

/**
 * Type/size component props
 */
export interface EntitiesListSwitchProps {
  /**
   * Additional CSS class name
   */
  className?: string;

  /**
   * Disable controls
   */
  disabled?: boolean;

  /**
   * Allow toggle size
   */
  size?: boolean;

  /**
   * Allow toggle type
   */
  type?: boolean;
}

/**
 * Items wrapper renderer props
 */
export interface ItemsWrapperRendererProps<
  T extends BaseEntityWithPk
> extends BaseItemProps<T> {
  /**
   * Pregenerated `key` value for nested items
   */
  key: string;
}

/**
 * Empty tile/row props
 */
export interface EmptyItemProps {
  /**
   * Allow drop flag
   */
  allowDrop: boolean;
}

/**
 * Items wrapper
 */
export interface ItemsWrapperProps<
  E extends React.ElementType,
  T extends BaseEntityWithPk
> {
  /**
   * Class name to add when drag is over empty tile/row
   */
  activeClassName: string;

  /**
   * Component tag type, `tbody`/`div`/etc
   */
  as: E;

  /**
   * Item renderer component
   */
  renderer: React.FC<ItemsWrapperRendererProps<T>>;

  /**
   * Renderer for empty tile/row
   */
  empty: React.FC<EmptyItemProps>;

  /**
   * Text value to filter items
   */
  filter?: string;

  /**
   * Item to string tokens converter
   * @param item
   */
  toTokens?(item: T): string[];
}

/**
 * Base items list context generator
 */
export type BaseEntityListProps<
  T extends BaseEntityWithPk
> = {
  /**
   * Checkbox generator
   * @param item
   */
  checkbox?(item: T): React.ReactElement;

  /**
   * Content generator for the `more` section
   */
  content?(item: T, onClose: () => void): React.ReactNode;

  /**
   * List of disabled items
   */
  disabled?: boolean | ((item: T) => boolean);

  /**
   * Test for section expand availability
   * @param item Item
   */
  hasExpand?(item: T): boolean;

  /**
   * List of highlighted entities
   */
  highlighted?: T | T[];

  /**
   * All items
   */
  items: T[];

  /**
   * On click callback
   * @param item
   */
  onClick?(item: T): void;

  /**
   * Callback to be called when "more" section changes visibility
   * @param item Item to expand, empty if section is closing
   */
  onExpand?(item?: T): void;

  /**
   * Size of entities
   */
  size?: EntitiesSize;

  /**
   * List type
   */
  type?: EntitiesListType;
} & ({
  /**
   * A list of `name` of other list from which drop should be
   * accepted
   */
  accept?: string[];

  /**
   * Max number of items in list (to hide items with the index
   * exceeding this number on drop)
   */
  max?: number;

  /**
   * Name of the list
   */
  name?: string;

  /**
   * On drag start event, must be provided to allow drag from list
   * @param item
   */
  onDrag?(item: T): void;

  /**
   * Drop listener
   */
  onDrop?(item: T, position: number, source: string): void;

  /**
   * A type of items in the list, used to detect is item allowed to
   * drop
   */
  what?: string;
} | {
  accept?: never;
  max?: never;
  name?: never;
  onDrag?: never;
  onDrop?: never;
  what?: never;
});

/**
 * Core properties for additional list functionality
 */
export type CoreEntityListProps<
  T extends BaseEntityWithPk
> = BaseEntityListProps<T> & {
  /**
   * Additional CSS class name for the root component
   */
  className?: string;

  /**
   * Text to find in items
   */
  filter?: string;

  /**
   * Loading state, when number is passed, displays certain
   * amount of loading tiles/rows
   */
  loading?: boolean | number;

  /**
   * Item renderer component
   */
  renderer: React.FC<ItemsWrapperRendererProps<T>>;

  /**
   * Display nice "not found" component when list is empty and
   * not loading
   */
  showNotFound?: boolean | React.ReactNode;

  /**
   * Converter of item to text tokens
   * @param item
   */
  toTokens?(item: T): string[];
}

export type WithGetData<T, D> = D extends T
  // eslint-disable-next-line @typescript-eslint/ban-types
  ? { }
  : { getData(item: T): D; };

/**
 *
 */
export type AdvancedEntityListProps<
  T extends BaseEntityWithPk,
  D extends BaseEntityWithPk = T
> = Omit<CoreEntityListProps<T>, 'toTokens' | 'renderer'> & {
  /**
   * See `AdvancedItemProps` for details
   */
  getData?(item: T): D;

  /**
   * Link generator
   */
  link?: LinkGetterType<D>;

  /**
   * Callback for like control
   * @param item
   * @param like
   */
  onLike?(item: T, like: boolean): void;

  /**
   * Callback for link cotrol
   * @param item
   * @param target
   */
  onLink?(item: T, target: HTMLElement): void;

  /**
   * Callback for menu control
   * @param item
   * @param target
   */
  onMenu?(item: T, target: HTMLElement): void;
};

/**
 * Additional for list of items supporting block and pin
 */
type PublishableEntityListProps<
  T extends BaseEntityWithPk,
  D extends BaseEntityWithPk = T
> = AdvancedEntityListProps<T, D> & {
  /**
   * Callback for block control
   * @param item
   * @param blocked
   */
  onBlock?(item: T, blocked: boolean): void;

  /**
   * Callback for pin control
   * @param item
   * @param pin
   */
  onPin?(item: T, pin: boolean): void;
}

/**
 * Collections list component properties
 */
export type CollectionsProps<
  T extends BaseEntityWithPk = Collection,
  D extends Collection = Collection
> = PublishableEntityListProps<T, D> & CollectionItemExtraProps<D> & {
  status?: CollectionActionStatus;
}

/**
 * Games list component properties
 */
export type GamesProps<
  T extends BaseEntityWithPk = Game,
  D extends Game = Game
> = AdvancedEntityListProps<T, D> & GameItemExtraProps<D>;

/**
 * Moments list component properties
 */
export type MomentsProps<
  T extends BaseEntityWithPk = Moment,
  D extends Moment = Moment
> = PublishableEntityListProps<T, D> & MomentItemExtraProps<D> & {
  status?: MomentActionsStatus;
};

/**
 * Players list component properties
 */
export type PlayersProps<
  T extends BaseEntityWithPk = Player,
  D extends Player = Player
> = AdvancedEntityListProps<T, D> & PlayerItemExtraProps<D>;

/**
 * Extra props for tiles lists
 */
export interface TilesExtraProps {
  /**
   * Render everything in a single line
   * Handy when you need to show first items fit to the screen
   */
  singleLine?: boolean;

  /**
   * Enables slider mode, when all items are rendered in a
   * single line with the scroll bar underneath
   */
  slider?: boolean;
}

/**
 * Extra props for table lists
 */
export interface ListsExtraProps<T extends BaseEntityWithPk> {
  /**
   * Display table header
   */
  header?: boolean;

  /**
   * A way to render something below the item
   * @param item
   */
  more?(item: T): React.ReactNode;
}

export type UseEntitiesReturnType<
  T extends BaseEntityWithPk
> = Pick<EntitiesListContextType<T>, 'findItem'>
