// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "e08082f13c14d8b59a19aaad0d5adf9b-less";
export var backgroundGrayGradient = "_07dcd48be9fb09956edd05423c9dc53a-less";
export var tileImage = "_3674623bbb5421208f75cc46a2eaae70-less";
export var small = "_76fa04bd402d409e01284d8af276dae0-less";
export var textbox = "_439ef2eed6ecbf3afc9453a9b149ead8-less";
export var editable = "_2fb03f625e8853e750cb77b24e83e59c-less";
export var textboxPlaceholder = "_1e01f460704bc90849f34bc7ff701c79-less";
export var suggestionItem = "_18318b800f7b62e177a965bfc3e43ff6-less";
export var suggestionItemTag = "_06a42d51b839aa18e723364a4d1bff71-less";
export var tag = "_5462f8b2636f648edbc463d0591c1272-less";
export var team = "_9b208ac2900516ff5ef3f6e891b4772a-less";
export var player = "b3bd7512b5b8a28774e8be9d630de767-less";
export var container = "b13371601beb24b66c04b049bdfcad4a-less";
export var disabled = "_6c3f1855299cb995a5d4b95a5a853437-less";
export var counter = "_12603fff6c1d776eb55fc8a428053c3a-less";
export var plainText = "c76c5a1bb6dcb0031c77296f5abe2318-less";
export var placeholder = "_7b984a04fd9a69512f731f8a5d66c477-less";