// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "ced9b14425ae465e717fa960aefb236c-less";
export var backgroundGrayGradient = "_8136d11913597341b82eaefa22190ed4-less";
export var tileImage = "_5c6f2afa6705678c1fedda49c6b47818-less";
export var small = "e47940631e7cc891bbbf62ebe7c7ce0e-less";
export var root = "f552dc77a45a7ba218b876ca73b335fa-less";
export var prev = "b8764df68830a79c910677d9b2ec3046-less";
export var next = "a871d20ca35ab8e21c41d5db7592ccd7-less";
export var withScroll = "_6cd4b2ff241682f980192102a78a4064-less";
export var container = "_2781bb944686fc6a6d7d645a04fb20eb-less";
export var hasPrev = "cf55e551eba088f73d5cdfb818615eaa-less";
export var hasNext = "_6293f966abfcb7f2f88074a8d115a7ce-less";
export var hasBoth = "_1927083ef4ea5fcf9a63ef940673c1e0-less";
export var fadeIn = "_9eb97556920b1ff1f6027a2606949bbd-less";
export var spin = "_94d47b7e845af2c328ad9ecc179276ec-less";