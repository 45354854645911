// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_7983835ef31b4951e67e9d57c1fd2a08-less";
export var backgroundGrayGradient = "_478c5c36022dc81c268a756263c2bd2f-less";
export var tileImage = "_7bd78cd98d600e7302bdfd6e0f48bb8e-less";
export var small = "fd225c3098f3f8926d80c13bffdf532e-less";
export var select = "cbd77feb413cf77a42d45d441b2dd01a-less";
export var blocked = "_84a1d69bf7f29b6a5940eb24de7b554a-less";
export var checkbox = "fec4848336cacc487a24b05041222e1e-less";
export var selecting = "e65f8ba33b7f68e022b5fd81af77560d-less";
export var disabled = "c30a12f68cccc8305ad2682109f7c615-less";
export var hidden = "_6521e76b3d4674bcefa519e0259a5b8e-less";
export var rtile = "dff4dca4ff089ce2d46d425ce0e7e605-less";
export var thumbnail = "c03462423e67cd81f1ed2bed5b176360-less";
export var info = "_263406ab8e59e7d62d7b51d8301e8206-less";
export var name = "c7798a681fc42ff95c0f0850b8372ace-less";
export var extra = "_54f596b6f46706bc9770a32abdcc594a-less";
export var top = "ab17f2ee401242cdee4f7ab2b29f1919-less";
export var item = "c9b5e8e9794bc48f6214e4f5fbe1d4e7-less";
export var noEffect = "_8ea947bb318e381b2a37dfdfee9be265-less";
export var highlighted = "_5e2980f9401c3f9ad770575dcf57e95c-less";
export var drop = "f374a403e05bae22c4b97c52e5cebbc4-less";
export var nodrop = "_7f74fb0114a807e3626f667eaadfec68-less";
export var control = "bd5c5d2e16d5ad56db47e74aa2fe9556-less";
export var visible = "_6d5efe7daf1582811feec3d1bafef2aa-less";
export var dragover = "_9717c54584f1a3eafb13a1816a0743f5-less";
export var tiles = "_52c7b613ecae271d0cf9fe470ea130ba-less";
export var game = "_78d071853e82d2677348fea417184e0b-less";
export var teams = "_094977b94723df94498925b98d5bb1af-less";
export var team = "_6285fa69771aef674ba63b51f4d89ad6-less";
export var date = "e8e7bc09ac1cb2b334a216cc3b3b448b-less";
export var live = "_435a3a963cdfac1d73e8db1bb8aceac6-less";
export var player = "_71a1660171bd4bb28bb12aa5232180a1-less";
export var photo = "_97ffe224ed7c5f2501be715e91d36725-less";
export var moment = "bfc4065d41b41d7bea320a4f8e0d7481-less";
export var momentDescription = "_5e03c5178a973b55944f7c32a4bc0b4b-less";
export var creator = "b00f12a99b74d17681e4d1fc5f36dfe2-less";
export var created = "b2c5ce67077096cfdeafa69e254cc8b8-less";
export var score = "_18c5a3a854780175fd6bc32813b9220f-less";
export var position = "_2b1baf4d5cbebd7243b4188ab4b1f607-less";
export var base = "_80cd54fc0c3c72bcdfd2cdd431903c93-less";
export var algo = "c847b85cbc3d84dd124cc6ff8461ee56-less";
export var auto = "_5121fa06069bd1ddf6cfcf05a55f3aa7-less";
export var fadeIn = "a0e32306d63e25ce9a81386d7ce10a97-less";
export var spin = "_8a307e1b8c2ced14d1b311058fc58359-less";