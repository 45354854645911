// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "fa56c9d2ec829999b4db529c171fadbc-less";
export var backgroundGrayGradient = "_795a38076309d11f10687c2926bf7abb-less";
export var tileImage = "_71cd3fc86a9141f0391e421c10f1b53f-less";
export var small = "_5dca9b19ff9046fe41853b6dcd96a229-less";
export var root = "_72362cb73ccdec9135bd383d2ecb55d8-less";
export var hidden = "_24262e9642f1bebfcf2ee0ef8840e968-less";
export var content = "c7a6b7c4d7d3433c4c0766121157999e-less";
export var like = "d822dcbaab81f71d0d15b77c0b925b7e-less";
export var download = "_41181a64584d1d14961465ad9dd59738-less";
export var remove = "e2e97ab6c53a03dd58ca9dfe5c65cee9-less";
export var collection = "_62854bc5545b8823620f3c75f8eba010-less";
export var none = "_77b127bebc5feb20dd6ecee5f2db1c83-less";
export var all = "_8e48f38b23df771d5be57c3c6e690b13-less";
export var loading = "_0baf1b0feabd86c6426e63e49431a45e-less";
export var fadeIn = "_3858d2d9b24ed823cbaae8088721a088-less";
export var spin = "_1751ae2d4deb518ebc4412b596c9db63-less";