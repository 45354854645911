// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "a3fb8230bcb93986204845d4fc511725-less";
export var backgroundGrayGradient = "_97040d5ed3c28b8538910040d5185fd5-less";
export var tileImage = "_961e1c8d892409a6d6da758eee8bb2e7-less";
export var small = "_651796f3a21cf8297b726b7db725a2f0-less";
export var root = "_7e9761e4635a0fd047d567297419b25e-less";
export var avatar = "da8178b0302ffc0a4ac0437f250133e7-less";
export var theme = "b8449df63229a1904d443e66e803a7cc-less";
export var algo = "d426b3c9b61dfdc256ac989c658f30cb-less";
export var auto = "_2b096e841b18b1f5289bbf1d944bb9d4-less";
export var fadeIn = "_73c9657a4e9f713a44fb9ee5f1f2e1c5-less";
export var spin = "_86bf7e83425e86cd69df5a50f527c945-less";