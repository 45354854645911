import { ReactComponent as Menu } from '@mdi/svg/svg/dots-horizontal.svg';
import * as cx from 'classnames';
import { ReactComponent as Loading } from 'images/spinner.svg';
import { memoize } from 'lodash/fp';
import * as React from 'react';
import { Form, Overlay } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GameQAStatus, What } from 'weplayed-typescript-api';

import { useProfile } from 'common/hooks/useProfile';

import { Button } from 'cms/components/Button';
import { ConfirmationModal } from 'cms/components/ConfirmationModal';
import { GameInfo } from 'cms/components/GameInfo';
import { DISCOVER$ } from 'cms/routes';

import { GameProviderContext } from '../GameProvider/constants';
import { MODE } from '../GameProvider/types';
import { GameModal } from '../types';
import { QA_LABELS } from './constants';
import * as s from './GameControlsHeadline.m.less';
import { Props } from './types';

export const GameControlsHeadline: React.FC<Props> = function GameControlsHeadline({
  className, ...props
}) {
  const {
    game, loading, mode, user, cms, toReview, recentTeam,
    onCMS, onQA, onCreatePlaylist, onEndStream,
  } = React.useContext(GameProviderContext);
  const { settings, saveSettings } = useProfile();

  const [modal, onModal] = React.useState<GameModal>();
  const [show, setShow] = React.useState(false);
  const handleToggle = React.useCallback(() => setShow(!show), [show]);
  const handleClose = React.useCallback(() => setShow(false), []);
  const button = React.useRef<HTMLButtonElement>();
  const container = React.useRef<HTMLDivElement>();
  const [stoppingLive, setStoppingLive] = React.useState(false);
  const [creatingPlaylists, setCreatingPlaylists] = React.useState(false);

  const cms_namespaces = React.useMemo(
    () => [...(game.cms_namespaces || [])].map((n) => n || '').sort(),
    [game?.cms_namespaces],
  );

  const handleChangeCMS = React.useCallback(({ target }) => {
    onCMS(target.value);
    handleClose();
  }, [handleClose, onCMS]);

  type HandleSwitchChange = (
    name: keyof typeof settings,
  ) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSwitchChange: HandleSwitchChange = React.useCallback(
    memoize((name: keyof typeof settings) => (
      { target }: React.ChangeEvent<HTMLInputElement>,
    ): void => saveSettings({ [name]: target.checked })),
    [saveSettings],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleShowModal = React.useCallback(
    memoize((name: GameModal) => (): void => {
      handleClose();
      onModal(name);
    }),
    [onModal, handleClose],
  );

  const handleQA = React.useCallback(({ target }) => {
    handleClose();
    onQA(target.value);
  }, [handleClose, onQA]);

  const entries: React.ReactNode[] = React.useMemo(() => {
    const is_staff = user?.is_staff;
    const is_cms = user?.is_cms_user;

    const ent: React.ReactNode[] = [];

    if (is_staff) {
      ent.push(
        (
          <li key="moment_score">
            <label htmlFor="moment_score">Show moment score</label>
            <div className={s.control}>
              <Form.Check
                checked={settings.game_moment_scores}
                disabled={loading}
                id="moment_score"
                onChange={handleSwitchChange('game_moment_scores')}
                type="switch"
              />
            </div>
          </li>
        ),
        (
          <li key="moment_status">
            <label htmlFor="moment_status">Show moment status</label>
            <div className={s.control}>
              <Form.Check
                checked={settings.game_moment_status}
                disabled={loading}
                id="moment_status"
                onChange={handleSwitchChange('game_moment_status')}
                type="switch"
              />
            </div>
          </li>
        ),
      );
    }

    if (cms_namespaces.length > 1) {
      ent.push(
        <li key="cms_namespace">
          <label htmlFor="cms_namespace">CMS namespace</label>
          <div className={s.control}>
            <select
              disabled={loading}
              id="cms_namespace"
              onChange={handleChangeCMS}
              value={cms || ''}
            >
              {cms_namespaces.map((name) => (
                <option value={name} key={name}>
                  {name || 'BASE'}
                </option>
              ))}
            </select>
          </div>
        </li>,
      );
    }

    const staff: React.ReactNode[] = [];

    if (is_staff) {
      if (game.live_now) {
        staff.push(
          <li key="endstream">
            <div className={s.control}>
              <Button
                disabled={loading || stoppingLive}
                loading={stoppingLive}
                onClick={handleShowModal(GameModal.STREAM_END)}
              >
                End Stream
              </Button>
            </div>
          </li>,
        );
      } else if (game.can_create_playlist) {
        staff.push(
          <li key="endstream">
            <div className={s.control}>
              <Button
                disabled={loading || creatingPlaylists}
                loading={creatingPlaylists}
                onClick={handleShowModal(GameModal.CREATE_PIVOTAL_PLAYLIST)}
              >
                Create Playlist
              </Button>
            </div>
          </li>,
        );
      }

      if (!game.live_now) {
        const { review_qa_status } = game;
        staff.push(
          <li key="qa">
            <label htmlFor="game_review">Game review status</label>
            <div className={s.control}>
              <select
                disabled={loading}
                onChange={handleQA}
                value={review_qa_status || ''}
              >
                {(typeof review_qa_status !== 'number') && (
                  <option key=""> </option>
                )}
                {Object.keys(GameQAStatus).filter((k) => !Number.isNaN(Number(k))).map((k) => (
                  <option key={k} value={k}>{QA_LABELS[k]}</option>
                ))}
              </select>
            </div>
          </li>,
        );
      }

      if (mode === MODE.NONE && toReview.length && !is_cms && game.expected_reviewed) {
        staff.push(
          <li key="reviewed">
            {`${(game?.moments.length ?? 0) - toReview.length} of ${game?.moments.length} Moments Reviewed (${game.expected_reviewed} expected)`}
          </li>,
        );
      }
    }

    if (staff.length && ent.length) {
      ent.push(<li key="divider" className={s.divider} />);
    }

    ent.push(...staff);

    return ent;
  }, [
    cms,
    cms_namespaces,
    creatingPlaylists,
    game,
    handleChangeCMS,
    handleQA,
    handleShowModal,
    handleSwitchChange,
    loading,
    mode,
    settings?.game_moment_scores,
    settings?.game_moment_status,
    stoppingLive,
    toReview?.length,
    user?.is_cms_user,
    user?.is_staff,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleModal = React.useCallback(
    memoize((proceed: boolean) => (): void => {
      if (proceed) {
        if (modal === GameModal.CREATE_PIVOTAL_PLAYLIST) {
          setCreatingPlaylists(true);
          onCreatePlaylist();
        } else if (modal === GameModal.STREAM_END) {
          setStoppingLive(true);
          onEndStream();
        }
      }
      onModal(null);
    }),
    [modal],
  );

  return (
    <div className={cx(s.root, className)} {...props}>
      <GameInfo game={game} disableLinks withDate className={s.score} />
      {user?.is_cms_user && recentTeam && (
        <Link
          to={DISCOVER$.buildPath({ section: What.GAMES, uid: game.pk, sub: What.MOMENTS })}
          className={s.home}
        />
      )}
      {entries.length ? (
        <button
          className={cx(s.toggle, mode !== MODE.NONE && s.disabled)}
          disabled={loading}
          onClick={handleToggle}
          ref={button}
          type="button"
        >
          {loading ? <Loading /> : <Menu />}
        </button>
      ) : null}
      <div className={s.container} ref={container} />
      <Overlay
        container={container.current}
        onHide={handleClose}
        placement="bottom"
        rootClose
        show={show}
        target={button.current}
        transition
      >
        <ul className={s.menu}>
          {entries}
        </ul>
      </Overlay>

      <ConfirmationModal
        cancelText="No"
        confirmText="Yes!"
        message="Are you sure you want to end this live stream now? This operation cannot be undone!"
        onCancel={handleModal(false)}
        onConfirm={handleModal(true)}
        show={modal === GameModal.STREAM_END}
        title="End stream?"
      />

      <ConfirmationModal
        cancelText="No"
        confirmText="Yes!"
        message="Are you sure you want to create the pivotal-moments playlist for this game?"
        onCancel={handleModal(false)}
        onConfirm={handleModal(true)}
        show={modal === GameModal.CREATE_PIVOTAL_PLAYLIST}
        title="Create pivotal-moments playlist?"
      />
    </div>
  );
};
