import { ReactComponent as Hide } from '@mdi/svg/svg/cancel.svg';
import { ReactComponent as Check } from '@mdi/svg/svg/check-circle-outline.svg';
import { ReactComponent as CodeTags } from '@mdi/svg/svg/code-tags.svg';
import { ReactComponent as Clone } from '@mdi/svg/svg/content-copy.svg';
import { ReactComponent as Delete } from '@mdi/svg/svg/delete.svg';
import { ReactComponent as Download } from '@mdi/svg/svg/download.svg';
import { ReactComponent as Flag } from '@mdi/svg/svg/flag.svg';
import { ReactComponent as Info } from '@mdi/svg/svg/information-outline.svg';
import { ReactComponent as Publish } from '@mdi/svg/svg/monitor.svg';
import { ReactComponent as Pencil } from '@mdi/svg/svg/pencil.svg';
import { ReactComponent as PlaylistPlus } from '@mdi/svg/svg/playlist-plus.svg';
import { ReactComponent as Game } from '@mdi/svg/svg/shape-plus.svg';
import { ReactComponent as Share } from '@mdi/svg/svg/share-variant.svg';
import * as cx from 'classnames';
import * as React from 'react';

import { Menu, MenuButtonHoC, MenuGroup } from '../Menus';
import * as s from './MomentActionsDropdown.m.less';
import { DropdownActionType, MomentActionsDropdownProps } from './types';

export const MomentActionsDropdown: React.FC<
MomentActionsDropdownProps<DropdownActionType>
> = function MomentActionsDropdown({
  className,
  invoke,
  item,
  onClose,
  placement,
  showClone,
  showCollection,
  showDelete,
  showDownload,
  showEdit,
  showEmbed,
  showFlag,
  showGame,
  showInfo,
  showReview,
  showPublish,
  showShare,
  showVisibility,
  status,
  target,
}) {
  const onClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onClose();
    const action = e.currentTarget.name as DropdownActionType;

    if (action === 'hide') {
      invoke({ action, moment: item, hidden: !item.is_hidden });
    } else {
      invoke({ action, moment: item });
    }
  }, [invoke, item, onClose]);

  const Button = React.useMemo(() => MenuButtonHoC<DropdownActionType>({
    onClick,
    pk: item?.pk,
    prefix: 'moment-actions',
    status,
  }), [onClick, item?.pk, status]);

  return (
    <Menu
      item={item}
      target={target}
      className={className}
      onClose={onClose}
      placement={placement}
    >
      {item && (
        <>
          <MenuGroup>
            <Button action="info" icon={Info} show={showInfo}>Info</Button>
          </MenuGroup>
          <MenuGroup>
            <Button
              action="collections"
              icon={PlaylistPlus}
              show={showCollection}
            >
              Playlist
            </Button>
            <Button action="download" icon={Download} show={showDownload}>Download</Button>
          </MenuGroup>
          <MenuGroup>
            <Button action="share" icon={Share} show={showShare}>Share</Button>
            <Button action="publish" icon={Publish} show={showPublish}>Publish</Button>
            <Button action="embed" icon={CodeTags} show={showEmbed}>Embed</Button>
          </MenuGroup>
          <MenuGroup>
            <Button
              action="edit"
              // WPM-1589
              className="no-mobile"
              icon={Pencil}
              show={showEdit}
            >
              Edit
            </Button>
            <Button action="clone" icon={Clone} show={showClone}>Clone</Button>
            <Button action="flag" icon={Flag} show={showFlag}>Flag</Button>
            <Button action="game" icon={Game} show={showGame}>Game Mode</Button>
            <Button
              action="review"
              className={cx((item.publication?.reviewed || item.reviewed) && s.reviewed)}
              icon={Check}
              show={showReview}
            >
              Review
            </Button>
            <Button action="hide" danger={!item.is_hidden} icon={Hide} show={showVisibility}>
              {item.is_hidden ? 'Unblock' : 'Block'}
            </Button>
          </MenuGroup>
          <MenuGroup>
            <Button action="delete" danger icon={Delete} show={showDelete}>Delete</Button>
          </MenuGroup>
        </>
      )}
    </Menu>
  );
};
