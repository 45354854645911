import * as cx from 'classnames';
import * as React from 'react';

import { hasTouch } from 'common/utils/features';

import { FadeInImage } from 'cms/components/FadeInImage';

import * as s from './ItemPreview.m.less';
import { ItemPreviewProps } from './types';

let container: HTMLElement;

/* istanbul ignore else */
export const engageTouch = (): void => {
  document.body.addEventListener('touchstart', (e) => {
    const target = e.target as HTMLElement;

    if (container) {
      container.classList.remove(s.hover);
      container = null;
    }

    if (target.parentElement.classList.contains(s.root)) {
      container = target.parentElement;
      container.classList.add(s.hover);
    }
  }, { passive: true });
};

if (hasTouch) {
  engageTouch();
}

export const resetContainer = (): void => {
  container = null;
};

/**
 * Item Preview Component
 * Have fade in effect on load, also can show animated webp preview on hover.
 * Additionally, can darken everything except certain area of the image
 * @param param Component parameters
 * @returns JSX
 */
export const ItemPreview: React.FC<ItemPreviewProps> = function ItemPreview({
  alt,
  animated,
  highlight,
  className,
  enable = true,
  src,
  ...props
}) {
  // style for image mask
  const style = React.useMemo<React.CSSProperties>(() => {
    if (highlight) {
      const pcfy = (...arr: number[]): string => arr.map((m) => `${m * 100}%`).join(' ');
      const sp = (size, pos): number => (size === 1 ? pos : (pos - 0.5) / (1 - size) + 0.5);
      return {
        maskPosition: pcfy(sp(highlight.width, highlight.x), sp(highlight.height, highlight.y)),
        maskSize: pcfy(highlight.width, highlight.height),
      };
    }
    return null;
  }, [highlight]);

  // style for animated preview image holder
  const astyle = React.useMemo<React.CSSProperties>(() => {
    if (animated) {
      return { backgroundImage: `url(${animated})` };
    }
    return null;
  }, [animated]);

  return (
    <div
      className={cx(s.root, className)}
      data-testid="item-preview-root"
      {...props}
    >
      {src && (
        <FadeInImage
          alt={alt}
          data-testid="item-preview-image"
          src={src}
        />
      )}
      {enable && animated && (
        <div
          className={s.animated}
          data-testid="item-preview-animated"
          style={astyle}
        />
      )}
      {style && <div className={s.area} style={style} />}
    </div>
  );
};
