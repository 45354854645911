// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_93c480cb13ba370f34a0bc5ce1f14514-less";
export var backgroundGrayGradient = "ae5b1e384477d1e564d336810f829c05-less";
export var tileImage = "_82d94557e209ba53575d9e01aeb9097a-less";
export var small = "d7c072629846a8e522ca53cf5bf1c0ce-less";
export var root = "ebfb6605f137f8fa9e0927d8039f328a-less";
export var toggle = "_8ff41916f589656a0f66c36bbe6290a8-less";
export var loading = "e356200f87c385c348409c9388234583-less";
export var label = "c0c0349adacd18092c887a65523ab573-less";
export var input = "_9cb1e5184efb87f8c9b16289b5f20dd1-less";
export var fadeIn = "d7422f26ca17fc511421076e19f673ea-less";
export var spin = "ff35c7e1a28b49bc58e5c0ae2061e011-less";