// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_873067061ccd0063fccc987ad8175f0c-less";
export var backgroundGrayGradient = "_33d9c19042d789094329038e2b6c9e53-less";
export var tileImage = "_3efec69fa9cda3eb40dc19d093e165c6-less";
export var small = "_7b5537ada25fec365b05c7cb3a9424b8-less";
export var disabled = "_887488edae10a46c8e9a3c89a7af6cf6-less";
export var blocked = "db35e69e77faf552533bbf65bec517da-less";
export var thumbnail = "_05d3b348ce2923d1845b2971f73aa1ba-less";
export var name = "b5172599371fa647a0a98264bba9a38c-less";
export var live = "d18c4a1236fb3546e6ccf14bcbea3760-less";
export var game = "_04a43016cd9c28f96a94b2adf5c83c9d-less";
export var control = "_8157749d0908747975dfccb5618e3c06-less";
export var visible = "_0058929b9c8f6849851f83d23466edfe-less";
export var item = "f9430e62ba35c7fb25b8c3edfe9fbe44-less";
export var selecting = "_0660e8281bef2c7f83568db4a6705ea0-less";
export var select = "_198e85fc643fe5bbb704c3aedd824533-less";
export var hidden = "_9caa1735d85fc274502860724ecaf9a6-less";
export var drop = "_5faf01eeb0b8af36c2d9914b088c0038-less";
export var nodrop = "_3520be75d9749bb39ce3c974958ea894-less";
export var odd = "_4ac786437d5f3e988b3716e43e546740-less";
export var noEffect = "_8ecce5d5b36ed02c86f0b8e3c8e8c03a-less";
export var highlighted = "d3d02ee72e4213860c6d263896c270d4-less";
export var checkbox = "cbe3365c981af5052dc10ee82947a84e-less";
export var dragover = "c7e144828715b7891cdf5836a65aa4f4-less";
export var avatar = "_2f1c701824aeb39008ac5a0907233d6a-less";
export var gameLabel = "_12ccebc7b0bfe9d533e4ecb98f7af09f-less";
export var base = "_9976645b9a47864ea538177f0a557142-less";
export var algo = "_8868ca17277c3be1c0d746ad3261e8dd-less";
export var auto = "_54a8c63c5740d6615f4fef502eb3b961-less";
export var tdThumbnail = "_0abee8c2902e252454729304cafb63b3-less";
export var extra = "d13e129b9fa696a5513c727a08523913-less";
export var fadeIn = "_0c188281ec7b558b8f6047102390f93e-less";
export var spin = "_8004489560f25609290af28472195ade-less";