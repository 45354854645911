// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_9935e57295b118359b3b9cfc5d289c6a-less";
export var backgroundGrayGradient = "_75c8e6e1c9933b26b7be629759cb52ec-less";
export var tileImage = "_58c7dfe50c22b672f04f4443cf5e5fdc-less";
export var small = "b2aa57c1c5ac9fa0e93401f770792a28-less";
export var checkbox = "_475039e9c00143529f82cd10877199a8-less";
export var control = "_4f196ea068768f6090c75f60c694c2df-less";
export var teams = "_376049791f669096d79c13b822446661-less";
export var thumbnail = "_073d962a3a06515bbf5e4d625de6c49d-less";
export var position = "_8be4f36a91729b785eb4747104b89344-less";
export var name = "_6b2c86988b2a6a76e0d269633c00e1bc-less";
export var sport = "c01f44ead8c2e3fa3aa50cfa010c0714-less";
export var athlete = "_5699bbc020f8cd383e3d169b97246cc7-less";
export var date = "_151c34045f469ed1f744798ae1095632-less";
export var game = "_1534c7e7c1b9dc06540e9970ab5fbb5f-less";
export var creator = "bb837099302a7fa45c62ea89d38229bf-less";
export var score = "b3550c1a93d71b81d992d9cbe6e5b364-less";
export var moments = "_4349a3395b3ecf0960c8f0c6c84a4f59-less";
export var fadeIn = "_492889e1375fe75faf186992a7e5cf2f-less";
export var spin = "_6aba99af8da195766c17d8782235d02f-less";