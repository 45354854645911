// extracted by mini-css-extract-plugin
export var poster = "_1b470e7ff9871984447f421d549cf101-less";
export var hidden = "_345ddfce528a10beab7f760c385431f1-less";
export var imaAdContainer = "_0bb3d7315b9e10752a8f016f401ea561-less";
export var video = "_2df1002cfa1752e622c5aa8e246ead95-less";
export var root = "_9a25b869bb2e2c063feec8bef9fc2256-less";
export var fullscreen = "_0553d6a52a0d48a88f9560dda3a0ea6a-less";
export var controls = "e68f105a59a4578bcfe2ac3d0779224c-less";
export var controlsRoot = "c0ead6f7c3052dc2e0a4927aa1cea4f8-less";
export var visible = "_262f7b23052736d183ac074bb802fd15-less";
export var rwdTap = "_30de2e875cc34221b9a6d259e1477d88-less";
export var fwdTap = "cfb85c42873903ca5e1471f83952172f-less";
export var loader = "_19aebe6346c74de2b6ebd52354afb7f1-less";
export var loading = "_1c6675a0e8a4bcc74569baca97b28c24-less";
export var vjsSpinnerShow = "d80e4922871f123262c0eb4f21829cc9-less";
export var vjsSpinnerSpin = "abc54320bb11b2ede4e83e9302d3c380-less";
export var vjsSpinnerFade = "_666252cdfa70a59a4281e53534eca5f2-less";
export var big = "_50d911c5d88d95cce8d45ccb54a178b4-less";
export var bounce = "_59b10592fc8b61a794aed5d5e74ee7b4-less";
export var button = "f50de122ac0f93b0245cfa9852b80c3e-less";
export var prev = "a645947d10ccdfe427512a043045f261-less";
export var next = "_9a4a23a3eb944b23285917973527e1de-less";
export var help = "_17b17c6344052e7d5fd4dad257ab6cae-less";
export var fadeIn = "dc82681767a5ee0ef3a217a9121f53e6-less";
export var children = "_7f1906766e79dac043173170143ca492-less";