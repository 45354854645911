// extracted by mini-css-extract-plugin
export var timelineCollapsedHeight = "35px";
export var markerGroupWidth = "10px";
export var markerSpacing = "5px";
export var markerWidth = "7px";
export var root = "_059fe07f0f43d37ce110217e6527866f-less";
export var holder = "a4606d21d942f17adb621f17532ee012-less";
export var momentGroups = "_78ca059f99218bc2e61a3fd7c9b47ff6-less";
export var momentGroup = "_9aa17dc3c2475755cc721616f04130f5-less";
export var moments = "_2171d1c64be24fbcad56fbe40ebe3b38-less";
export var active = "b4943a4f78c5e34dabaec1cef0967081-less";
export var pulseMoment = "_7d610a48f530341d27610c4f5e647452-less";
export var counter = "f6cee6d0293fa3897aa8e4d00c320404-less";
export var progress = "e1053eedd6302c2f6a096bb3a77b5071-less";
export var scrubber = "_4cfb94a09fbe43d381f7eb66298958f1-less";
export var dot = "_7516a66591419b8f79a010c0b7c71f26-less";
export var bar = "_03c769a0d40af1ae7b77c3e289090ecc-less";
export var scroller = "_6189512354dca608660f01768c814755-less";
export var popupHolder = "da530637a92a2ec069f7d7d034cee303-less";
export var popup = "_38cd13338515310a48f459a5737bd991-less";
export var zoom = "_285da2f4f4729be12b49b140f5db77d1-less";
export var segments = "a6cb08c75961f5e8b2d4f6808625f311-less";
export var segment = "_4f97c6639aef0fddc3168f9711ba5fee-less";
export var withGroups = "ee223214ef073a94738ad7181a845d4b-less";