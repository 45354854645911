// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_191bdd71f007456beefeffccc5a55819-less";
export var backgroundGrayGradient = "d40cee1aa5d6825c61e40a5cc2a552ba-less";
export var tileImage = "_109bb88846b3858aeebed22e67f1abc5-less";
export var small = "e1d92f2baa2add8ab1bc0a1671bb2461-less";
export var textbox = "_65353d30aea5a4a60bb84ecf1e0240c3-less";
export var editable = "_5af85119c206a604e3413bf46e5ca86d-less";
export var textboxPlaceholder = "aca98a4b53e293d3cc451862568a797b-less";
export var suggestionItem = "_6553b7b77e177f403074a03d086a6c47-less";
export var suggestionItemTag = "e450cdb2a6505b9541ce63724aa31d7e-less";
export var tag = "_8395413995f78ebc065f67e269e49cfe-less";
export var team = "a69bac474aa8a784865bb62611328c31-less";
export var player = "_8a922ba1922201162d0c95b707bfc712-less";
export var container = "_87ff958c784fe57979feada7df79013d-less";
export var disabled = "_165c68f4606b02f3e04f5069eeeec668-less";
export var counter = "_03f73f5b3d2e869d09e0e0a20912e865-less";
export var plainText = "ae97a79b46f237fb80dfade79b8a6b32-less";
export var placeholder = "_99bec29eabef738c2dcef687329eda1e-less";
export var fadeIn = "_4049eee8f92363d12537cded935f52af-less";
export var spin = "_22992d6797cb3d4992795f0767c7fe27-less";