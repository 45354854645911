import './CMS.m.less';

import * as cx from 'classnames';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useApplication } from 'common/hooks/useApplication';
import { useAuth } from 'common/hooks/useAuth';
import {
  identify as identifyAnalytics, init as initAnalytics, initFullStory,
} from 'common/utils/analytics';

import { Footer } from 'cms/components/Footer';
import { Header } from 'cms/components/Header';
import { Admin } from 'cms/containers/Admin';
import { ChangePassword } from 'cms/containers/ChangePassword';
import { Game } from 'cms/containers/Game';
import { Login } from 'cms/containers/Login';
import { Registration } from 'cms/containers/Registration';
import { RegistrationConfirm } from 'cms/containers/RegistrationConfirm';
import { ResetPassword } from 'cms/containers/ResetPassword';
import { ResetPasswordConfirm } from 'cms/containers/ResetPasswordConfirm';
import { ExportsContext, useCreateExportsContext } from 'cms/hooks/useExports';
import { CLIENT_URLS, GAME$ } from 'cms/routes';

import { Errors } from '../Errors';

const {
  USER: { CHANGE_PASSWORD },
  AUTH: { RESET_PASSWORD, RESET_PASSWORD_CONFIRM, REGISTRATION, REGISTRATION_CONFIRM, LOGIN },
} = CLIENT_URLS;

export const CMS: React.FC = function CMS() {
  const { subscription, profile } = useAuth();
  const { error } = useApplication();

  React.useEffect(() => {
    initAnalytics();
    initFullStory(true);
  }, []);

  React.useEffect(() => {
    if (subscription?.pk) {
      identifyAnalytics(subscription.pk, profile);
    }
  }, [profile, profile?.username, subscription?.pk]);

  const downloads = useCreateExportsContext();

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="WePlayed: When the Game Ends, the Story Begins">
        <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />
        <link
          rel="icon"
          type="image/png"
          href="https://media.weplayed.com/favico/favico.png"
        />
      </Helmet>
      <ToastContainer hideProgressBar />
      <Errors error={error}>
        <ExportsContext.Provider value={downloads}>
          <Header />
          <div id="content" className={cx(!profile && 'd-flex align-items-center mt-5')}>
            {profile ? (
              <Switch>
                <Route path={GAME$.route} component={Game} />
                <Route path={LOGIN.route}>
                  <Redirect to="/" />
                </Route>
                <Route path="*" component={Admin} />
              </Switch>
            ) : (
              <Switch>
                <Route path={RESET_PASSWORD_CONFIRM.route} component={ResetPasswordConfirm} />
                <Route path={RESET_PASSWORD.route} component={ResetPassword} />
                <Route path={CHANGE_PASSWORD.route} component={ChangePassword} />
                <Route path={REGISTRATION.route} component={Registration} />
                <Route path={REGISTRATION_CONFIRM.route} component={RegistrationConfirm} />
                <Route path="/" component={Login} />
              </Switch>
            )}
          </div>
          {!profile && <Footer />}
        </ExportsContext.Provider>
      </Errors>
    </>
  );
};
