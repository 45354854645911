import cx from 'classnames';
import * as React from 'react';
import { EmbedAdsSpec, Moment } from 'weplayed-typescript-api';

import {
  MomentPlayer as BaseMomentPlayer,
} from 'common/components/MomentPlayer';
import { MomentTrimmer } from 'common/components/MomentTrimmer';
import {
  RefType as MomentTrimmerRef,
} from 'common/components/MomentTrimmer/types';
import { usePrevious } from 'common/hooks/usePrevious';
import { EventContext } from 'common/utils/events';
import {
  createMomentEventDispatcher, MomentEventDispatcher, searchPlayer, searchTag,
  searchTeam,
} from 'common/utils/moments';

import { Avatar } from 'cms/components/Avatar';
import { Button } from 'cms/components/Button';
import { GameHeadline } from 'cms/components/GameHeadline';
import {
  MomentDescription, MomentDescriptionReadonly,
} from 'cms/components/MomentDescription';

import { AspectRatioProportions } from './constants';
import * as s from './PopupPlayer.m.less';
import { AspectRatio, MomentPlayerProps } from './types';

export const MomentPlayer: React.FC<MomentPlayerProps> = function MomentPlayer({
  cropBox: [_ratio = null, _cropBox = null] = [],
  cropHelp,
  disabled,
  edit: _edit,
  info: _info = true,
  moment: outerMoment,
  onCropBox,
  onCancel,
  onDone,
  onMomentUpdate,
  onNext,
  onPrevious,
  onSave,
  paused,
}) {
  // Shorthand for document
  const d = document;

  // Edit mode
  const edit = _edit && !onCropBox;

  // Show moment info
  const info = _info && !onCropBox;

  // Moment trimmer reference
  const trimmerRef = React.useRef<MomentTrimmerRef>(null);

  // Crop box element, placed over video
  const cropboxRef = React.useRef<HTMLDivElement>(null);

  // Reference to keep initial position of crop box area (left-top) in pixels
  // relative to screen
  const cropposRef = React.useRef<[number, number]>(null);

  // Reference to the crop area selector
  const centerCropRef = React.useRef<HTMLDivElement>(null);

  // Cropping state
  const [isCropping, setIsCropping] = React.useState(false);

  // Combined data for current crop settings
  const [[ratio, cropBox], setAR] = React.useReducer((state, a) => {
    if (Array.isArray(a)) return a;
    if (typeof a === 'object') {
      return [state[0], {
        ...state[1],
        x: state[1].width / 2 + a.x,
        y: state[1].height / 2 + a.y,
      }];
    }
    if (!a) return [null, null];

    return state;
  }, [_ratio, _cropBox]);

  // Moment to play/edit
  const [moment, setMoment] = React.useState<Moment<EmbedAdsSpec>>(outerMoment);

  // Trimmer position
  const [trimmerPosition, setTrimmerPosition] = React.useState<number>(moment?.start);

  // Moment playback events dispatcher
  const eventDispatcher: MomentEventDispatcher = React.useMemo(
    () => (moment?.pk ? createMomentEventDispatcher(moment, EventContext.ADMIN) : undefined),
    [moment],
  );

  // Moment start/stop change by trimmer listener
  const handleChangeMomentBoundaries = React.useCallback((m: Moment<EmbedAdsSpec>) => {
    setMoment(m);
    if (onMomentUpdate) {
      onMomentUpdate(m);
    }
  }, [onMomentUpdate]);

  // Handle moment save button press
  const handleSaveMoment = React.useCallback(() => {
    onSave(moment);
  }, [moment, onSave]);

  // Player search proxy function
  const handleSearchPlayer = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchPlayer(term, moment.game, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Tag search proxy function
  const handleSearchTag = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchTag(term, moment.game.sport.pk, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Team search proxy function
  const handleSearchTeam = React.useCallback(
    (term: string, transformFunc, callbackFunc): void => {
      searchTeam(term, moment.game, transformFunc, callbackFunc);
    },
    [moment?.game],
  );

  // Moment description change callback
  const handleChangeMomentDescription = React.useCallback(
    (description: string, name: string): void => {
      const m = { ...moment, description, name };
      setMoment(m);
      if (onMomentUpdate) {
        onMomentUpdate(m);
      }
    },
    [moment, onMomentUpdate],
  );

  // Handles setting a new crop size
  // Also moves crop box back to the center or area after change
  const handleSetAspectRatio = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const ar = e.currentTarget.value as AspectRatio;
    let $crop = null;
    let $ratio = null;

    if (ar) {
      $ratio = ar;
      const r = AspectRatioProportions[ar];
      const { width, height } = cropboxRef.current.getBoundingClientRect();
      const isVertical = (width / height) > r;
      const box_width = isVertical ? height * r : width;
      const box_height = isVertical ? height : width / r;
      $crop = {
        height: box_height / height,
        width: box_width / width,
        // always put initial area at center
        y: 0.5,
        x: 0.5,
      };
    }

    setAR([$ratio, $crop]);
  }, []);

  // Handles crop box move
  const handleCropMove = React.useCallback((e: MouseEvent) => {
    if (cropposRef.current) {
      const { left, width, top, height } = cropboxRef.current.getBoundingClientRect();
      const { width: cwidth, height: cheight } = centerCropRef.current.getBoundingClientRect();

      const [x, y] = cropposRef.current;
      const posX = Math.max(0, Math.min(width - cwidth, (e.clientX - x - left))) / width;
      const posY = Math.max(0, Math.min(height - cheight, (e.clientY - y - top))) / height;
      setAR({ x: posX, y: posY });
    }
  }, []);

  // Set moment when external one changed
  React.useEffect(() => {
    setMoment(outerMoment);
  }, [outerMoment]);

  const prev_cropBox = usePrevious(_cropBox);

  React.useEffect(() => {
    if (prev_cropBox !== _cropBox) {
      setAR([_ratio, _cropBox]);
    }
  }, [_ratio, _cropBox, prev_cropBox]);

  // Fire onCropBox event only after mouse pointer is released
  React.useEffect(() => {
    if (!isCropping && onCropBox && cropBox !== _cropBox) {
      onCropBox(ratio, cropBox);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratio, cropBox, isCropping]);

  // Handles mouse pointer release after moving crop box
  const handleCropEnd = React.useCallback(() => {
    setIsCropping(false);
    cropposRef.current = null;
    cropboxRef.current.style.removeProperty('--box-transition');
    d.removeEventListener('mousemove', handleCropMove);
    d.removeEventListener('mouseup', handleCropEnd);
    d.removeEventListener('mouseleave', handleCropEnd);
  }, [d, handleCropMove]);

  // Handles mouse pointer grab to change crop area
  const handleCropStart = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    cropboxRef.current.style.setProperty('--box-transition', '0');
    const { x, y } = centerCropRef.current.getBoundingClientRect();
    cropposRef.current = [e.clientX - x, e.clientY - y];
    d.addEventListener('mousemove', handleCropMove);
    d.addEventListener('mouseup', handleCropEnd);
    d.addEventListener('mouseleave', handleCropEnd);
    setIsCropping(true);
  }, [d, handleCropEnd, handleCropMove]);

  // Crop box and mask styles
  const [centerStyle, maskStyle]: [React.CSSProperties, React.CSSProperties] = React.useMemo(() => {
    if (!cropBox) return [null, null];

    const pcfy = (...arr: number[]): string => arr.map((m) => `${m * 100}%`).join(' ');
    // Mask position is pretty tricky to calculate
    // When mask position is 50%, mask coordinate relative to which positioning
    // happens is also 50% (center)
    // When mask position is set to 0, mask coordinate sits on the left border
    // Same for 100% — coordinate sits on the right border
    // So basically here is scaling position from [mask_width, 1 - mask_width] to [0, 1]
    const sp = (size, pos): number => (size === 1 ? pos : (pos - 0.5) / (1 - size) + 0.5);

    return [
      {
        inset: pcfy(
          cropBox.y - (cropBox.height / 2),
          1 - cropBox.x - (cropBox.width / 2),
          1 - cropBox.y - (cropBox.height / 2),
          cropBox.x - (cropBox.width / 2),
        ),
      },
      {
        maskPosition: pcfy(sp(cropBox.width, cropBox.x), sp(cropBox.height, cropBox.y)),
        maskSize: pcfy(cropBox.width, cropBox.height),
      },
    ];
  }, [cropBox]);

  return (
    <>
      <div className={s.video}>
        <BaseMomentPlayer
          moment={moment}
          ads={false}
          bar={!edit}
          fullscreen={!edit}
          onEvent={!edit && eventDispatcher}
          onNext={!edit && onNext}
          onPosition={trimmerRef.current?.setTime}
          onPrevious={!edit && onPrevious}
          playing={!(edit || paused)}
          position={trimmerPosition}
          overlay={onCropBox && (
            <>
              <div
                className={cx(s.cropbox, !cropBox && s.hidden)}
                ref={cropboxRef}
                style={maskStyle}
              />
              <div
                className={s.cropCenter}
                ref={centerCropRef}
                style={centerStyle}
              >
                <button
                  type="button"
                  onMouseDown={handleCropStart}
                >
                  Move Crop Area
                </button>
              </div>
            </>
          )}
        />
        {info && (
          <GameHeadline
            className={s.headline}
            disableLinks
            game={moment.game}
          />
        )}
      </div>
      {edit && onCancel ? (
        <>
          <div className={s.adjust}>
            <MomentTrimmer
              disabled={disabled}
              moment={moment}
              // position={playerPosition}
              length={moment.video.duration}
              onPosition={setTrimmerPosition}
              onChange={handleChangeMomentBoundaries}
              ref={trimmerRef}
              videoUrl={moment.video.streaming_url}
            />
            <Button
              className={s.button}
              disabled={disabled}
              loading={disabled}
              onClick={handleSaveMoment}
              over
              variant="primary"
            >
              Save
            </Button>
            <Button
              className={s.button}
              disabled={disabled}
              variant="default"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <MomentDescription
            className={s.description}
            disabled={disabled}
            inEditMode
            moment={moment}
            onChanged={handleChangeMomentDescription}
            onSearchPlayer={handleSearchPlayer}
            onSearchTag={handleSearchTag}
            onSearchTeam={handleSearchTeam}
            textareaPlaceholder="Create a custom moment with #tags and @athlete mentions."
          />
        </>
      ) : (
        <div className={s.description}>
          {onCropBox && onCancel ? (
            <div className={s.crop}>
              <div>
                <ul>
                  <li>
                    <label>
                      <input
                        checked={!ratio}
                        name="aspect_ratio"
                        onChange={handleSetAspectRatio}
                        type="radio"
                        value=""
                      />
                      <div
                        className={cx(s.ratio, !ratio)}
                        style={{ aspectRatio: '16/9' }}
                      />
                      Original size
                    </label>
                  </li>
                  {Object.entries(AspectRatio).map(([k, v]) => (
                    <li key={k}>
                      <label>
                        <input
                          checked={ratio === v}
                          name="aspect_ratio"
                          onChange={handleSetAspectRatio}
                          type="radio"
                          value={v}
                        />
                        <div
                          className={cx(s.ratio, ratio === v)}
                          style={{ aspectRatio: String(AspectRatioProportions[v]) }}
                        />
                        {v}
                      </label>
                    </li>
                  ))}
                </ul>
                {cropHelp && <div className={s.help}>{cropHelp}</div>}
              </div>
              <div className={s.buttons}>
                <Button variant="primary" onClick={onDone}>Done</Button>
                <Button variant="secondary" onClick={onCancel}>Cancel</Button>
              </div>
            </div>
          ) : (
            <>
              <Avatar className={s.avatar} user={moment.curator} />
              <MomentDescriptionReadonly
                inline
                moment={moment}
                withDuration
              />
            </>
          )}
        </div>
      )}

    </>
  );
};
