/**
 * Returns current display orientation angle
 * @returns degrees
 */
export const getOrientationAngle = (): number => Math.abs(
  window.screen.orientation
    ? window.screen.orientation.angle
    : window.orientation as number,
);

/**
 * Tests VJS components readiness
 * @param ads Include ad components in test
 * @returns
 */
export const testReady = (
  ads: string,
): boolean => typeof window.videojs === 'function' && (
  !ads || (
    typeof window.videojsContribAds === 'function'
      // && typeof window.videojsIma === 'function'
      && typeof window.videojsIma?.default === 'function'
  )
);
