// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_78990ad715afda0a810bb0b5dc0cb27c-less";
export var backgroundGrayGradient = "_1e6d3eb431142a621c986191897ecd76-less";
export var tileImage = "_02d865a74dfbe606199b89fc3243c55f-less";
export var small = "_92e15ddc90640ce69934335619612180-less";
export var root = "e8057713f2edbe86c32c3b73167d7ee7-less";
export var dark = "_4291e4412cbf522b02a4ed63b3ed612e-less";
export var loading = "_998f90edc53bb33e5dd3cd84b4f24d66-less";
export var bounce = "ca1da7dfcf0a456c64b6dcf842bf944a-less";
export var fadeIn = "d3cd022ef1503449353d410ef4579933-less";
export var spin = "_1ffe4fce9bc5bafcbb9452381f10df8d-less";