// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_92bebc01a81326f5ac37fccbdf5e71e0-less";
export var backgroundGrayGradient = "_390f7f448f1d9290881e75b35e443e84-less";
export var tileImage = "f99bba0bcc860031c6896b4c90c48bd0-less";
export var small = "_82e06cc35976bd2da6f1c54d602a5818-less";
export var root = "d8d6498b7366ccd0696febab53ac5d18-less";
export var downloads = "c98a7e9b9e6f0cb54f71f86821e2231c-less";
export var menu = "a3a7a0c8e69fbe5daa609dcb27893e6a-less";
export var logo = "_965a39aab5c05fb1976783d21c9f50bc-less";
export var school = "_72dc2f86147d0bff65ba0e8d0852c2be-less";
export var search = "_17e5d4a185c6503c99d283eda98216d2-less";
export var settings = "e42fc9f685437311377031773e1d713f-less";
export var profile = "_7572b4a03b988f4a12610e4ea11d6efa-less";
export var back = "_3ab1f90af1c9fe0cb1862d5dcf6b4dda-less";
export var mc = "cd95378b8e47c8e8214bf5c4694502e3-less";
export var profileMenu = "e1ef48bcc5ba3b1a6051966264f06124-less";
export var name = "_2276e1a98a7942dbe75e2cfaa0f1c215-less";
export var email = "_2ccdf7aa2e5b4e35a383ee11b16e1388-less";
export var social = "c925d78f6c6137155b50af80f1793ee6-less";
export var facebook = "f5f9fa08f1fa517e36a3e3b5c096d4f4-less";
export var linkedin = "ab16604cbc557db0e9bcfe10fa021ee3-less";
export var instagram = "_835869c273dc97fb3dd4e3ecc50ef65b-less";
export var twitter = "_147cda8e8837bd6496cca37d851db19c-less";
export var divider = "_72ff4160b18c5ae543fdc8d205430ab5-less";
export var container = "_4ab713561eca190c0799950e4c8ee968-less";
export var form = "_03b1ce030083be25505d8c5b24744df2-less";
export var visible = "_3c048eb981fd99b7d0d5fa7309699fab-less";
export var fadeIn = "_0a505d8f1a3cb7bf6af899330bb19f0b-less";
export var spin = "_1830a231aa356f1d4031c00206da829b-less";