/**
 * Looks for a variable in a given object and resolves with variable value
 * or rejects promise depending on test result
 * @param name Variable name
 * @param scope Variable scope, defaults to `window`
 * @param timeout Lookup timeout, defailts to 1000ms
 */
export const ensureVariable = async <T>(
  name: string,
  scope = window,
  timeout = 1000,
): Promise<T> => {
  const start = new Date().valueOf();

  while (!(name in scope) || new Date().valueOf() - start <= timeout) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((ok) => { setTimeout(ok, 30); });
  }

  if (!(name in scope)) {
    throw new Error('No variable defined');
  }

  return scope[name];
};
