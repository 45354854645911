import * as cx from 'classnames';
import { formatDuration, intervalToDuration } from 'date-fns';
import { curry, intersection, isEmpty, memoize, pick } from 'lodash';
import * as React from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import {
  Athlete, Collection, CollectionTheme, EmbedAdsSpec, Game, ID, Moment, Team,
} from 'weplayed-typescript-api';

import { usePrevious } from 'common/hooks/usePrevious';
import { CollectionMomentsSortOrder } from 'common/types';
import { pickByPk } from 'common/utils/helpers';
import {
  guessMomentsSort, momentDuration, sortMoments,
} from 'common/utils/moments';

import { Button } from 'cms/components/Button';
import { useCollection } from 'cms/hooks/useCollection';
import { useCollections } from 'cms/hooks/useCollections';

import { HTMLSwitch } from '../HTMLSwitch';
import { MomentPlayer } from '../PopupPlayer/MomentPlayer';
import { SectionNav, SectionNavItem } from '../SectionNav';
import * as s from './CollectionModal.m.less';
import { CollectionSection, CollectionSectionRow } from './CollectionSection';
import {
  automaticText, CollectionThemeName, emptyPublish, privateText, publicText,
  REQUIRES_ATHLETE, REQUIRES_GAME, REQUIRES_TEAM,
} from './constants';
import { reducer } from './reducer';
import { MomentsContainer, testDragCancel } from './Sortables';
import {
  CollectionModalProps, MomentAction, ReducerAction, Section,
} from './types';
import { useData } from './useData';

export const CollectionModal: React.FC<CollectionModalProps> = function CollectionModal({
  clone,
  moments,
  onClose,
  onMomentSave,
  onSave,
  onSuccess,
  saving,
  uid,
  user,
}) {
  const {
    collection: { data },
  } = useCollection({ uid });

  const {
    create: [
      createCollection,
      { isLoading: isCreating, isSuccess: isCreatingSuccess, data: createdPk },
    ],
    update: [
      updateCollection,
      { isLoading: isUpdating, isSuccess: isUpdatingSuccess },
    ],
  } = useCollections();

  const [collection, action] = React.useReducer(reducer, {
    name: '',
    moments: moments || [],
    visibility: 1,
  });

  const [sort, setSort] = React.useState<CollectionMomentsSortOrder>(
    guessMomentsSort(collection.moments),
  );

  const [visibilityChanged, setVisibilityChanged] = React.useState(false);
  const [moment, setMoment] = React.useState<Moment<EmbedAdsSpec>>(null);

  const [section, setSection] = React.useState<Section>(Section.DETAILS);

  // extract games which presents on all moments
  // in fact it may have only one game, but it's easier to keep it in record
  const [team, game, athletes] = React.useMemo<[
    Pick<Team, 'pk' | 'name'>,
    Pick<Game, 'pk' | 'name'>,
    Array<Pick<Athlete, 'pk' | 'name'>>,
  ]>(
    () => {
      if (data) {
        return [
          data.dedicated_team,
          data.dedicated_game,
          data.dedicated_team_athlete ? [data.dedicated_team_athlete] : [],
        ];
      }

      if (data || !moments || moments.length === 0) {
        return [null, null, []];
      }

      const org_teams = user.org?.available_teams || [];

      const teams_dict: Record<ID, string> = {};
      const common_teams = intersection(
        ...moments.map(({ game: { team1, team2, sport: { name: sport } } }) => [team1, team2]
          .filter(({ pk }) => org_teams.includes(pk))
          .map(({ pk }) => {
            teams_dict[pk] = sport;
            return pk;
          })),
      );

      const games_dict: Record<ID, string> = {};
      const common_games = intersection(
        ...moments.map(({ game: { pk, name } }) => {
          games_dict[pk] = name;
          return [pk];
        }),
      );

      const athletes_dict: Record<ID, string> = {};
      const common_athletes = intersection(
        ...moments.map(({ athletes: a }) => a.map(({ id: pk, name, team_id }) => {
          if (org_teams.includes(team_id)) {
            athletes_dict[pk] = name;
            return pk;
          }

          return null;
        }).filter(Boolean)),
      );

      return [
        common_teams.length ? { pk: common_teams[0], name: teams_dict[common_teams[0]] } : null,
        common_games.length ? { pk: common_games[0], name: games_dict[common_games[0]] } : null,
        common_athletes.map((pk) => ({ pk, name: athletes_dict[pk] })),
      ];
    },
    [data, moments, user.org?.available_teams],
  );

  const [needs_team, needs_game, needs_athlete] = React.useMemo(() => [
    !uid && collection.theme && REQUIRES_TEAM.includes(collection.theme),
    !uid && REQUIRES_GAME.includes(collection.theme),
    !uid && REQUIRES_ATHLETE.includes(collection.theme),
  ], [collection.theme, uid]);

  const { availability } = useData({
    athletes: needs_athlete ? athletes : [],
    game: needs_game ? game : null,
    team: needs_team ? team : null,
    theme: collection.theme,
  });

  const visible = Boolean(uid || moments);

  React.useEffect(() => {
    setSection(Section.DETAILS);
    if (!uid) {
      action({
        action: 'set',
        collection: { name: '', moments: moments || [], visibility: 1 } as Collection,
      });
    }
    setMoment(null);
    setVisibilityChanged(false);
  }, [moments, uid]);

  React.useEffect(() => {
    if (data && !data.algo_maintained) {
      setSection(Section.MOMENTS);
    }
  }, [data]);

  React.useEffect(() => {
    if (!uid) {
      const teamId = team?.pk;
      const gameId = collection.theme ? game?.pk : null;
      const athleteId = athletes.length === 1 ? athletes[0].pk : null;

      action({
        action: 'update',
        dedicated_team: teamId,
        dedicated_game: gameId,
        dedicated_team_athlete: athleteId,
        blocked_from_team: !teamId,
        blocked_from_game: !gameId,
        blocked_from_team_athlete: !athleteId,
      });
    }
  }, [uid, needs_team, needs_game, needs_athlete, team, game, athletes, collection.theme]);

  React.useEffect(() => {
    if (availability.data && needs_athlete) {
      const available = athletes.filter(({ pk }) => availability.data[pk]);
      if (available.length === 1) {
        action({
          action: 'update',
          dedicated_team_athlete: available[0].pk,
          blocked_from_team_athlete: false,
        });
      }
    }
  }, [athletes, availability.data, needs_athlete]);

  React.useEffect(() => {
    if (data) {
      action({
        action: 'set',
        collection: {
          ...data,
          theme: clone ? null : data.theme,
        },
      });
      setSort(guessMomentsSort(data.moments));
    }
  }, [clone, data]);

  React.useEffect(() => {
    if (isUpdatingSuccess && onSuccess) {
      onSuccess();
    }
  }, [isUpdatingSuccess, onSuccess]);

  React.useEffect(() => {
    if (moments) {
      action({ action: 'update', moments });
    }
  }, [moments]);

  const prevSaving = usePrevious(saving);

  React.useEffect(() => {
    if (prevSaving && !saving && moment) {
      setMoment(null);
    } else if ((prevSaving && !saving && !moment) || isCreatingSuccess || isUpdatingSuccess) {
      onSuccess(createdPk);
    }
  }, [createdPk, isCreatingSuccess, isUpdatingSuccess, moment, onSuccess, prevSaving, saving]);

  const handleTheme = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
      const update: ReducerAction = {
        action: 'update',
        theme: value as CollectionTheme,
      };

      if (value) {
        update.visibility = 1;
      }

      action(update);
    },
    [],
  );

  const handleAthlete = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
      action({
        action: 'update',
        dedicated_team_athlete: value,
        blocked_from_team_athlete: false,
      });
    },
    [],
  );

  const handleSetVisibility = React.useCallback((priv) => {
    action({ action: 'update', visibility: priv ? 0 : 1 });
    setVisibilityChanged(true);
  }, []);

  const handleSave = React.useCallback(() => {
    if (uid && !clone) {
      const payload = {
        uid,
        ...pick(collection, 'name', 'visibility', 'algo_maintained'),
        moments: collection.moments !== data.moments ? collection.moments : undefined,
        ...(collection.visibility
          ? pick(collection, 'blocked_team', 'blocked_game', 'blocked_team_athlete')
          : emptyPublish
        ) };
      if (onSave) {
        onSave(uid, payload);
      } else {
        updateCollection({ uid, ...payload });
      }
    } else {
      const payload = {
        ...collection,
        // clear name if playlist has theme set
        name: collection.theme
          ? CollectionThemeName[collection.theme]
          : collection.name,
      };

      if (onSave) {
        onSave(null, { uid: clone ? uid : null, ...payload });
      } else {
        createCollection(payload);
      }
    }
  }, [uid, clone, collection, data?.moments, onSave, updateCollection, createCollection]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePublish = React.useCallback(
    (memoize(curry((key, value, e) => {
      if (!collection.theme) {
        if (key === 'blocked_from_team_athlete') {
          action({
            action: 'update',
            dedicated_team_athlete: value ? e.currentTarget.value : null,
            blocked_from_team_athlete: !value,
          });
        } else if (key === 'blocked_from_team') {
          action({
            action: 'update',
            dedicated_team: value ? e.currentTarget.value : null,
            blocked_from_team: !value,
          });
        }
      } else {
        action({
          action: 'update',
          [key]: value,
        });
      }
    }))),
    [collection],
  );

  const replaceMoment = React.useCallback((m: Moment, position?: number): void => {
    const idx = position ?? collection.moments.findIndex(pickByPk(m.pk));
    action({
      action: 'update',
      moments: [
        ...collection.moments.slice(0, idx),
        m,
        ...collection.moments.slice(idx + 1),
      ],
    });
  }, [collection.moments]);

  const handleCollectionMomentAction = React.useCallback(
    (m: Moment<EmbedAdsSpec>, act: MomentAction) => {
      const idx = collection.moments.indexOf(m);

      switch (act) {
        case 'top': {
          const [mmm] = collection.moments.splice(idx, 1);
          action({ action: 'update', moments: [mmm, ...collection.moments] });
          setSort(guessMomentsSort(collection.moments));
          break;
        }

        case 'bottom': {
          const [mmm] = collection.moments.splice(idx, 1);
          action({ action: 'update', moments: ([...collection.moments, mmm]) });
          setSort(guessMomentsSort(collection.moments));
          break;
        }

        case 'remove': {
          collection.moments.splice(idx, 1);
          action({ action: 'update', moments: [...collection.moments] });
          break;
        }

        case 'edit': {
          setMoment(m);
          replaceMoment({ ...m });
          break;
        }

        default: //
      }
    },
    [replaceMoment, collection.moments],
  );

  const handleCollectionMomentSortEnd = React.useCallback(({ oldIndex, newIndex }) => {
    let mmm = [...collection.moments];
    const [m] = mmm.splice(oldIndex, 1);
    mmm = [...mmm.slice(0, newIndex), m, ...mmm.slice(newIndex)];
    action({ action: 'update', moments: mmm });
    setSort(guessMomentsSort(collection.moments));
  }, [collection.moments]);

  const handleMomentsSort = React.useCallback(
    ($sort: CollectionMomentsSortOrder): void => {
      action({ action: 'update', moments: sortMoments(collection.moments, $sort) });
      setSort($sort);
    },
    [collection.moments],
  );

  const handleMomentEditCancel = React.useCallback(() => {
    replaceMoment(moment);
    setMoment(null);
  }, [moment, replaceMoment]);

  const unavailable = React.useMemo<number>(() => {
    if (availability.data) {
      const game_un = availability.data[game?.pk] ? 0 : 1;
      const athletes_un = athletes.filter(({ pk }) => !availability.data[pk]).length;

      return (needs_game && !needs_athlete)
        ? game_un
        : ((athletes_un === athletes.length && athletes.length) || 0);
    }

    return 0;
  }, [availability.data, athletes, game, needs_athlete, needs_game]);

  const hasPublish = Boolean(collection.visibility && !unavailable);

  const cantSave = isCreating || isUpdating || availability.isLoading
    || (!collection.name && !collection.theme)
    || (needs_team && !team)
    || (needs_game && !needs_athlete && !availability.data?.[collection.dedicated_game])
    || (needs_athlete && !availability.data?.[collection.dedicated_team_athlete]);

  const moment_count = collection?.moments.length || 0;

  const duration = React.useMemo(
    () => collection?.moments?.map(momentDuration).reduce((p, c) => p + c, 0) || null,
    [collection?.moments],
  );

  const hasVisibilityControl = (!uid || user.pk === data?.created_by.pk) && !collection.theme;

  const visibilityTitle = (
    <span className={collection.visibility ? s.publicText : s.privateText}>
      This playlist is
      {collection.visibility ? ' public' : ' private'}
    </span>
  );

  const automaticTitle = data?.algo_created && (
    <span className={collection.algo_maintained ? s.auto : s.manual}>
      Automatically Update
    </span>
  );

  return (
    <Modal
      show={visible}
      backdropClassName="modal-backdrop-blackout"
      keyboard
      onHide={onClose}
      size="lg"
    >
      <Modal.Header className={s.header}>
        <Modal.Title>
          {clone ? 'Clone Playlist: ' : ''}
          {uid ? data?.name : 'New Playlist'}
          {duration ? (
            <span className={s.info}>
              {`${moment_count} ${moment_count === 1 ? ' moment' : ' moments'} | ${
                formatDuration(intervalToDuration({
                  start: new Date(0),
                  end: new Date(duration * 1000),
                }))
              }`}
            </span>
          ) : null}
        </Modal.Title>
        {uid && (
          <SectionNav className={s.tabs}>
            <SectionNavItem
              active={section === Section.DETAILS}
              onClick={(): void => setSection(Section.DETAILS)}
            >
              Details
            </SectionNavItem>
            <SectionNavItem
              active={section === Section.MOMENTS}
              onClick={(): void => setSection(Section.MOMENTS)}
            >
              Moments
            </SectionNavItem>
          </SectionNav>
        )}
      </Modal.Header>
      {section === Section.DETAILS && (
        <Modal.Body>
          <div className={s.theme}>
            <Form.Group className={cx(s.type, availability.isLoading && s.loading)}>
              <Form.Label>Playlist Type</Form.Label>
              <Form.Control
                as="select"
                disabled={Boolean(uid || availability.isLoading || clone)}
                onChange={handleTheme}
                value={collection.theme || ''}
              >
                <option value="">Custom</option>
                {Object.values(CollectionTheme).map((key) => {
                  if (!uid && (
                    (REQUIRES_TEAM.includes(key) && !team)
                    || (REQUIRES_GAME.includes(key) && !game)
                    || (REQUIRES_ATHLETE.includes(key) && isEmpty(athletes))
                  )) {
                    return null;
                  }

                  return <option key={key} value={key}>{CollectionThemeName[key]}</option>;
              })}
              </Form.Control>
            </Form.Group>
          </div>

          {!collection.theme && (
            <Form.Group className={s.name}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                as="input"
                onChange={({ currentTarget: { value } }): void => action({ action: 'update', name: value })}
                value={collection.name}
              />
            </Form.Group>
          )}

          <div className={s.selector}>
            {needs_athlete && athletes.length > 1 && (
              <div className={s.checkboxes}>
                {athletes.map(({ pk, name: label }) => (
                  <Form.Check
                    checked={pk === collection.dedicated_team_athlete}
                    className={s.checkbox}
                    disabled={availability.isLoading || !availability.data?.[pk]}
                    custom
                    id={`dedicated_athlete_${pk}`}
                    key={pk}
                    label={(
                      <>
                        {label}
                        {availability.data && !availability.data?.[pk]
                          ? <span className={s.exist}>(This playlist already exist)</span>
                          : ''}
                      </>
                    )}
                    onChange={handleAthlete}
                    type="radio"
                    value={pk}
                  />
                ))}
              </div>
            )}
            {!!unavailable && (
              <div className={s.warning}>
                {(unavailable === 1
                  ? 'This playlist has already been created'
                  : 'These playlists have already been created')}
              </div>
            )}
          </div>

          {automaticTitle && (
            <CollectionSection disabled={Boolean(unavailable)}>
              <CollectionSectionRow title={automaticTitle}>
                {automaticText}
                <HTMLSwitch
                  checked={collection.algo_maintained}
                  label={collection.algo_maintained && 'Updated automatically'}
                  onChange={(algo_maintained): void => action({ action: 'update', algo_maintained })}
                />
              </CollectionSectionRow>
            </CollectionSection>
          )}

          <CollectionSection disabled={Boolean(unavailable)}>
            {(!hasVisibilityControl || (!uid && !visibilityChanged)) && (
              <CollectionSectionRow title={visibilityTitle}>
                {collection.visibility ? publicText : privateText}
              </CollectionSectionRow>
            )}
            {hasVisibilityControl && (
              <CollectionSectionRow
                note={!visibilityChanged && !uid ? '(Optional)' : null}
                title={(visibilityChanged || uid)
                  ? visibilityTitle
                  : `Make ${collection.visibility ? 'private' : 'public'}`}
              >
                {collection.visibility ? publicText : privateText}
                <HTMLSwitch
                  checked={!collection.visibility}
                  onChange={handleSetVisibility}
                />
              </CollectionSectionRow>
            )}
          </CollectionSection>

          {(!uid || clone) && (
            <CollectionSection disabled={!hasPublish}>
              <CollectionSectionRow
                note="(Optional)"
                title="Publish status"
              >
                Publishing a playlist allows it to be discovered on your website
                widget. You can change this at any time.
              </CollectionSectionRow>
              {team && (!collection.theme || collection.dedicated_team) && (
                <CollectionSectionRow>
                  {team?.name}
                  <HTMLSwitch
                    checked={(collection.visibility
                      && (collection.theme
                        ? !collection.blocked_from_team
                        : collection.dedicated_team === team.pk))
                      || false}
                    onChange={handlePublish('blocked_from_team')}
                    label={collection.visibility ? !collection.blocked_from_team && 'Published' : null}
                    value={team.pk}
                  />
                </CollectionSectionRow>
              )}
              {(!collection.theme || collection.dedicated_team_athlete)
                && athletes?.map(({ pk, name: label }) => {
                const checked = (collection.visibility
                  && (collection.theme
                    ? !collection.blocked_from_team_athlete
                    : collection.dedicated_team_athlete === pk))
                  || false;

                return (
                  (!collection.theme || collection.dedicated_team_athlete === pk) && (
                    <CollectionSectionRow key={pk}>
                      {label}
                      <HTMLSwitch
                        checked={checked}
                        onChange={handlePublish('blocked_from_team_athlete')}
                        label={checked && 'Published'}
                        value={pk}
                      />
                    </CollectionSectionRow>
                  )
                );
            })}
            </CollectionSection>
          )}
        </Modal.Body>
      )}
      {section === Section.MOMENTS && (
        <Modal.Body>
          {moment ? (
            <MomentPlayer
              disabled={saving}
              edit
              info={false}
              moment={moment}
              onCancel={handleMomentEditCancel}
              onMomentUpdate={replaceMoment}
              onSave={onMomentSave}
            />
          ) : (
            <>
              <div className={s.sort}>
                <div className={s.sortName}>{sort || 'Custom'}</div>
                <Dropdown onSelect={handleMomentsSort}>
                  <Dropdown.Toggle as="button" disabled={Boolean(moment)}>
                    Sort
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={s.menu}
                  >
                    <Dropdown.Item disabled>Sort by ...</Dropdown.Item>
                    {Object.values(CollectionMomentsSortOrder).map((o) => (
                      <Dropdown.Item
                        eventKey={o}
                        className={s.item}
                        key={o}
                      >
                        {o}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <MomentsContainer
                disabled={saving}
                editable={!collection.algo_maintained}
                hasEdit={Boolean(onMomentSave)}
                edit={moment?.pk}
                axis="y"
                helperClass={s.drag}
                items={collection.moments}
                onAction={handleCollectionMomentAction}
                onSortEnd={handleCollectionMomentSortEnd}
                shouldCancelStart={testDragCancel}
              />
            </>
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button
          disabled={Boolean(isCreating || isUpdating || saving || cantSave || moment)}
          loading={isCreating || isUpdating || saving}
          onClick={handleSave}
          type="button"
        >
          {uid ? 'Save' : 'Create'}
        </Button>
        {onClose && (
          <Button
            disabled={Boolean(moment)}
            onClick={onClose}
            type="button"
            variant="secondary"
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
