// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_021f38817d3a5512b57acc207f843649-less";
export var backgroundGrayGradient = "_85824269fc9c1b75469e4d17340b525b-less";
export var tileImage = "c83314b0277a3c15ea522c380fbb9720-less";
export var small = "f35aa6554318f4a4fa44ffccc2d9740c-less";
export var tiles = "_15ab453a332d655b75586ec6450151a0-less";
export var prev = "_69682767a146b8b5519b42ab62f24bb5-less";
export var next = "c94c39c25304f2268c85cb96e583129a-less";
export var hidden = "_742a16ec9ae9aff82799f3d609256b2a-less";
export var disabled = "d2e961796f47e28e05a9f6bb10410d52-less";
export var single = "_420d7bcddb373ec0951322f0eacf950e-less";
export var sliderContainer = "f1ee7c10e496dd867bff3a343b5b1db5-less";
export var slider = "_8da7b4393e9d43ce7d6c2c99b2f9150f-less";
export var hasPrevious = "_087ab48f8dbc7d1accec00c3f540f20b-less";
export var hasNext = "_714764181e3fb245bf7a735a55a8d8ef-less";
export var hasBoth = "c1029a265c71960c1471d826642671fb-less";
export var fadeIn = "c69903e82d33a1695cb4e95c374c51d4-less";
export var spin = "_97241866581fc5f14a40078a98a69f16-less";