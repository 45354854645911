import * as ACRO from 'images/bg/acro-cover.jpg';
import * as BASK from 'images/bg/bask-cover.jpg';
import * as FHOCK from 'images/bg/fieldhockey-cover.jpg';
import * as FOOT from 'images/bg/foot-cover.jpg';
import * as HOCK from 'images/bg/hock-cover.jpg';
import * as LAX from 'images/bg/lacr-cover.jpg';
import * as ROW from 'images/bg/rowing-cover.jpg';
import * as SOCC from 'images/bg/socc-cover.jpg';
import * as SOFT from 'images/bg/soft-cover.jpg';
import * as SWIM from 'images/bg/swim-cover.jpg';
import * as TENN from 'images/bg/tenn-cover.jpg';
import * as TF from 'images/bg/track-field-cover.jpg';
import * as VOLL from 'images/bg/voll-cover.jpg';
import * as WPOLO from 'images/bg/wpolo-cover.jpg';
import * as WRSTL from 'images/bg/wrstl-cover.jpg';
import { SportSlug } from 'weplayed-typescript-api';

const sportSlugToLabel: { [K in SportSlug]: string } = {
  MCBASE: 'Men’s baseball',
  MCBASK: 'Men’s basketball',
  MCFOOT: 'Men’s football',
  MCHOCK: 'Men’s hockey',
  MCLAX: 'Men’s lacrosse',
  MCROW: 'Men’s rowing',
  MCRUG: 'Men’s rugby',
  MCSOCC: 'Men’s soccer',
  MCSWM: 'Men’s swimming',
  MCTENN: 'Men’s tennis',
  MCTF: 'Men’s track and field',
  MCVOLL: 'Men’s volleyball',
  MCWPOLO: 'Men’s water polo',
  MCWRSTL: 'Men’s wrestling',
  WCACRO: 'Women’s acrobatics',
  WCBASK: 'Women’s basketball',
  WCFHOC: 'Women’s field hockey',
  WCHOCK: 'Women’s hockey',
  WCLAX: 'Women’s lacrosse',
  WCROW: 'Women’s rowing',
  WCRUG: 'Women’s rugby',
  WCSOCC: 'Women’s soccer',
  WCSOFT: 'Women’s softball',
  WCSWM: 'Women’s swimming',
  WCTENN: 'Women’s tennis',
  WCTF: 'Women’s track and field',
  WCVOLL: 'Women’s volleyball',
  WCWPOLO: 'Women’s water polo',
  WCWRSTL: 'Women’s wrestling',
};

export const getLabelForSlug = (sportSlug: string): string => sportSlugToLabel[sportSlug] || '';

const backgrounds: { [K in SportSlug]: string } = {
  MCBASE: SOFT,
  MCBASK: BASK,
  MCFOOT: FOOT,
  MCHOCK: HOCK,
  MCLAX: LAX,
  MCROW: ROW,
  MCRUG: undefined,
  MCSOCC: SOCC,
  MCSWM: SWIM,
  MCTENN: TENN,
  MCTF: TF,
  MCVOLL: VOLL,
  MCWPOLO: WPOLO,
  MCWRSTL: WRSTL,
  WCACRO: ACRO,
  WCBASK: BASK,
  WCFHOC: FHOCK,
  WCHOCK: HOCK,
  WCLAX: LAX,
  WCROW: ROW,
  WCRUG: undefined,
  WCSOCC: SOCC,
  WCSOFT: SOFT,
  WCSWM: SWIM,
  WCTENN: TENN,
  WCTF: TF,
  WCVOLL: VOLL,
  WCWPOLO: WPOLO,
  WCWRSTL: WRSTL,
};

export const getImageForSlug = (sportSlug: string): string => backgrounds[sportSlug] || FOOT;
