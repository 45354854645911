import { GameAction, Moment, User } from 'weplayed-typescript-api';

export enum EntriesSortOrder {
  CHRONOLOGICAL = 'Chronological',
  HIGH = 'Score - high to low',
  LOW = 'Score - low to high',
}

export interface GameControlMomentsHubEntryProps {
  action?: GameAction;
  active: boolean;
  disabled: boolean;
  moment?: Moment;
  onClick(moment: Moment): void;
  onCreateMoment(text: string, copied_pbp_id: number): void;
  onLike(moment: Moment, like: boolean): void;
  onLink(action: GameAction): void;
  onMenu(moment: Moment, target: HTMLElement): void;
  user?: User;
}

export interface Props extends React.HTMLProps<HTMLDivElement> {
  entryHeaderClassName?: string
  headerClassName?: string;
}

export interface MenuEntry {
  moment: Moment;
  target: HTMLElement;
}
