import { Location } from 'history';
import { without } from 'lodash/fp';
/* eslint-disable prefer-arrow-callback */
import * as React from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  Collection, Game, ID, Moment, PinEntity, Player, What, WhatType,
} from 'weplayed-typescript-api';

import { useApplication } from 'common/hooks/useApplication';
import { useHandleQuery } from 'common/hooks/useHandleQuery';
import { usePreviousValue } from 'common/hooks/usePreviousValue';
import { useProfile } from 'common/hooks/useProfile';
import { EventContext } from 'common/utils/events';
import { pkify } from 'common/utils/helpers';

import {
  CollectionActionDropdown, useCollectionActions,
} from 'cms/components/CollectionActions';
import {
  Action as CollectionAction, ActionType as CollectionActionType,
  BlockCollection,
} from 'cms/components/CollectionActions/types';
import {
  isAction as isCollectionAction,
} from 'cms/components/CollectionActions/utils';
import { CollectionModal } from 'cms/components/CollectionModal';
import { CopyLinkDropdown } from 'cms/components/CopyLinkDropdown';
import { useGameActions } from 'cms/components/GameActions';
import {
  Action as GameAction, ActionType as GameActionType,
} from 'cms/components/GameActions/types';
import {
  MomentActionsDropdown, useMomentActions,
} from 'cms/components/MomentActions';
import {
  Action as MomentAction, ActionType as MomentActionType,
} from 'cms/components/MomentActions/types';
import { isAction as isMomentAction } from 'cms/components/MomentActions/utils';
import { usePlayerActions } from 'cms/components/PlayerActions';
import {
  Action as PlayerAction, ActionType as PlayerActionType,
} from 'cms/components/PlayerActions/types';
import { PopupPlayer } from 'cms/components/PopupPlayer';
import { PlayerMode } from 'cms/components/PopupPlayer/types';
import { SelectPanel } from 'cms/components/SelectPanel';
import { ShareSidebar } from 'cms/components/ShareSidebar';
import { StaffEditWarningModal } from 'cms/components/StaffEditWarningModal';
import { Page404 } from 'cms/containers/Errors';
import { useCollection } from 'cms/hooks/useCollection';
import { CreateParams, UpdateParams } from 'cms/hooks/useCollections/types';
import { useMoment } from 'cms/hooks/useMoment';
import { BlockMoment } from 'cms/hooks/useMoments/types';
import { useUploads } from 'cms/hooks/useUploads';
import { LIBRARY$, SETTINGS$ } from 'cms/routes';

import * as s from './Admin.m.less';
import { AdminContext, UploadsContext } from './constants';
import { Menu } from './Menu';
import {
  AdminContextType, EntriesShare, EntryLink, EntryMenu, EntryShare, PinProps,
} from './types';

const Library = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-library" */ './Library')).Library,
}));
const Settings = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-settings" */ './Settings')).Settings,
}));

const Clients = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-clients" */ './Clients')).Clients,
}));
const Staff = React.lazy(async () => ({
  default: (await import(/* webpackChunkName: "cms-staff" */ './Staff')).Staff,
}));

const Loader: React.FC = function Loader() {
  useHandleQuery({ isLoading: true });
  return <div />;
};

export const Admin: React.FC = function Admin<
  T extends What.MOMENTS | What.COLLECTIONS | What.GAMES | What.PLAYERS
>() {
  const { navType } = useApplication();
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    navType('sidebar', true);

    return (): void => {
      navType('sidebar', false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { profile: user, saveSettings, settings } = useProfile();

  const prev = usePreviousValue(location);

  const [back, dispatchBack] = React.useReducer((p: Location[], action: 'push' | 'pop' | 'clear') => {
    switch (action) {
      case 'push': return [...p, prev];
      case 'pop': return p.slice(0, -1);
      case 'clear': return [];
      default: return p;
    }
  }, [] as Location[]);

  const pushBack = React.useCallback(() => dispatchBack('push'), []);
  const popBack = React.useCallback(() => dispatchBack('pop'), []);

  const [momentUid, setMomentUid] = React.useState<ID>();
  const [collectionUid, setCollectionUid] = React.useState<ID>();

  const [selectionType, setSelectionType] = React.useState<T>();
  const [selection, setSelection] = React.useState<Array<WhatType<T>>>([]);
  const [items, setItems] = React.useState<Array<WhatType<T>>>();

  const [mode, setMode] = React.useState<PlayerMode>();

  const [
    delayedMomentAction,
    setDelayedMomentAction,
  ] = React.useState<MomentAction<'edit' | 'hide' | 'delete'>>();

  const hasSelection = selection && selection.length !== 0;

  const hasLocationEntity = React.useMemo(
    () => location.hash.includes('playlist=') || location.hash.includes('moment='),
    [location.hash],
  );

  const handleResetEdit = React.useCallback(() => {
    setMode(null);
    if (!hasLocationEntity) {
      setMomentUid(null);
      setCollectionUid(null);
    }
  }, [hasLocationEntity]);

  const showMoment = React.useCallback((uid: ID) => {
    history.replace(`${location.pathname}${location.search}#moment=${uid}`);
  }, [history, location.pathname, location.search]);

  const handleMomentClick = React.useCallback((m: Moment) => {
    showMoment(m.pk);
  }, [showMoment]);

  const handleCollectionClick = React.useCallback((c: Collection) => {
    history.replace(`${location.pathname}${location.search}#playlist=${c.pk}`);
  }, [history, location.pathname, location.search]);

  React.useEffect(() => {
    const [where, uid] = location.hash.replace(/^#/, '').split('=');

    if (where === 'moment') {
      setMomentUid(uid);
      setCollectionUid(null);
    } else if (where === 'playlist') {
      setCollectionUid(uid);
      setMomentUid(null);
    } else if (!where && (momentUid || collectionUid)) {
      setCollectionUid(null);
      setMomentUid(null);
      handleResetEdit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const handleClosePlayer = React.useCallback(() => {
    if (hasLocationEntity) {
      history.replace(`${location.pathname}${location.search}`);
    } else {
      setMomentUid(null);
      setCollectionUid(null);
    }
  }, [hasLocationEntity, history, location.pathname, location.search]);

  const [menu, setMenu] = React.useState<EntryMenu>();
  const [link, setLink] = React.useState<EntryLink>();
  const [share, setShare] = React.useState<EntryShare>();
  const [shares, setShares] = React.useState<EntriesShare>();

  const { moment: {
    data: momentShared,
    isLoading: isMomentShareLoading,
  } } = useMoment({
    uid: share?.type === What.MOMENTS && share?.uid,
  });

  const { collection: {
    data: collectionShared,
    isLoading: isCollectionShareLoading,
  } } = useCollection({
    summary: true,
    uid: share?.type === What.COLLECTIONS && share?.uid,
  });

  useHandleQuery(
    { isLoading: isMomentShareLoading },
    { isLoading: isCollectionShareLoading },
  );

  React.useEffect(() => {
    if (momentShared) {
      setShares({ type: What.MOMENTS, items: [momentShared] });
    } else if (collectionShared) {
      setShares({ type: What.COLLECTIONS, items: [collectionShared] });
    }
  }, [momentShared, collectionShared]);

  const handleMomentStart = React.useCallback(
    (a: MomentAction<MomentActionType>): false | void => {
      if (isMomentAction(a, 'share')) {
        handleClosePlayer();
        setShare({ type: What.MOMENTS, uid: a.moment.pk });
        return false;
      }

      if (isMomentAction(a, 'publish') && !a.publish) {
        handleClosePlayer();
      }

      if (!delayedMomentAction
          && user.is_staff
          && settings.staff_edit_warning
          && (
            isMomentAction(a, 'edit') || isMomentAction(a, 'hide') || isMomentAction(a, 'delete')
          )
          && a.moment.is_base
      ) {
        setDelayedMomentAction(a);
        return false;
      }

      if (isMomentAction(a, 'edit') || isMomentAction(a, 'clone')) {
        setMomentUid(a.moment.pk);
        setMode(isMomentAction(a, 'edit') ? PlayerMode.MOMENT_EDIT : PlayerMode.MOMENT_CLONE);
        return false;
      }
    },
    [delayedMomentAction, handleClosePlayer, settings.staff_edit_warning, user.is_staff],
  );

  const handleCollectionStart = React.useCallback(
    (action: CollectionAction<CollectionActionType>): false | void => {
      if (isCollectionAction(action, 'share')) {
        handleClosePlayer();
        setShare({ type: What.COLLECTIONS, uid: action.uid });
        return false;
      }

      if (isCollectionAction(action, 'publish') && !action.publish) {
        handleClosePlayer();
      }

      if (
          (isCollectionAction(action, 'update') || isCollectionAction(action, 'clone'))
          && !action.collection
      ) {
        setCollectionUid(action.uid);
        setMode(isCollectionAction(action, 'clone')
          ? PlayerMode.COLLECTION_CLONE
          : PlayerMode.COLLECTION_EDIT);
      }
    },
    [handleClosePlayer],
  );

  const handleMomentDone = React.useCallback(
    (a: MomentAction<MomentActionType>, result) => {
      if (isMomentAction(a, 'create') || isMomentAction(a, 'update')) {
        if (isMomentAction(a, 'create')) {
          showMoment(result);
        }
      } else if (isMomentAction(a, 'delete') && momentUid) {
        handleClosePlayer();
      } else if (
        hasSelection
          && ['collections', 'bulk_like', 'bulk_download', 'bulk_delete'].includes(a.action)
      ) {
        setSelection([]);
      }
    },
    [momentUid, hasSelection, showMoment, handleClosePlayer],
  );

  const handleGameDone = React.useCallback(
    ({ action }: GameAction<GameActionType>) => {
      if (hasSelection && action === 'bulk_like') {
        setSelection([]);
      }
    },
    [hasSelection],
  );

  const handleCollectionDone = React.useCallback(
    function handleCollectionDone<TT extends CollectionActionType>(
      action: CollectionAction<TT>,
      result,
    ) {
      const isClone = isCollectionAction(action, 'clone');
      const isUpdate = isCollectionAction(action, 'update');
      if (isClone || isUpdate) {
        if (isClone) {
          setCollectionUid(result);
        }
        setMomentUid(null);
        setMode(null);
      } else if ((
        isCollectionAction(action, 'bulk_like')
        || isCollectionAction(action, 'bulk_delete')
        || isCollectionAction(action, 'bulk_download')
      ) && hasSelection) {
        setSelection([]);
      } else if (isCollectionAction(action, 'remove')) {
        handleClosePlayer();
      }
    },
    [handleClosePlayer, hasSelection],
  );

  const handlePlayerDone = React.useCallback(
    ({ action }: PlayerAction<PlayerActionType>) => {
      if (action === 'bulk_like' && hasSelection) {
        setSelection([]);
      }
    },
    [hasSelection],
  );

  const collectionActions = useCollectionActions({
    onDone: handleCollectionDone,
    onStart: handleCollectionStart,
    sendDownloadEmail: settings.send_download_email,
    user,
  });
  const gameActions = useGameActions({
    user,
    onDone: handleGameDone,
  });
  const momentActions = useMomentActions({
    onDone: handleMomentDone,
    onStart: handleMomentStart,
    sendDownloadEmail: settings.send_download_email,
    user,
  });
  const playerActions = usePlayerActions({
    user,
    onDone: handlePlayerDone,
  });

  const handleOpenCollectionMenu = React.useCallback(
    (c: Collection, target: HTMLElement, simple?: boolean) => {
      setMenu({ type: What.COLLECTIONS, item: c, target, simple });
    },
    [],
  );

  const handleCollectionLike = React.useCallback(({ pk }: Collection, like: boolean) => {
    collectionActions.invoke({ action: 'like', like, uid: pk });
  }, [collectionActions]);

  const handleGameLike = React.useCallback(({ pk }: Game, like: boolean) => {
    gameActions.invoke({ action: 'like', like, uid: pk });
  }, [gameActions]);

  const handleOpenMomentMenu = React.useCallback(
    (c: Moment, target: HTMLElement, simple?: boolean) => {
      setMenu({ type: What.MOMENTS, item: c, target, simple });
    },
    [],
  );

  const handleMomentLike = React.useCallback((mm: Moment, like: boolean) => {
    momentActions.invoke({ action: 'like', like, moment: mm });
  }, [momentActions]);

  const handleCloseMenu = React.useCallback(() => {
    setMenu(null);
  }, []);

  const handlePlayerLike = React.useCallback(({ pk }: Player, like: boolean) => {
    playerActions.invoke({ action: 'like', like, uid: pk });
  }, [playerActions]);

  const handleCollectionSave = React.useCallback((uid: ID, c: CreateParams | UpdateParams) => {
    if (uid) {
      // update
      collectionActions.invoke({
        action: 'update',
        collection: c as UpdateParams,
        uid,
      });
    } else if ('uid' in c) {
      // clone
      collectionActions.invoke({
        action: 'clone',
        collection: c,
        success: 'Playlist cloned successfully and saved to your library',
        uid,
      });
    } else {
      // create
      collectionActions.invoke({
        action: 'create',
        collection: c,
        uid: null,
      });
    }
  }, [collectionActions]);

  const handleMomentSave = React.useCallback((m: Moment) => {
    momentActions.invoke({
      action: m.pk ? 'update' : 'create',
      success: !m.pk ? 'Moment cloned successfully and saved to your library' : null,
      fail: !m.pk ? 'Moment clone failed' : null,
      moment: m,
    });
  }, [momentActions]);

  const handleMomentsCollection = React.useCallback((moments: Moment[]) => {
    momentActions.invoke({
      action: 'collections',
      moment: null,
      moments,
    });
  }, [momentActions]);

  const handleMassLike = React.useCallback(() => {
    if (!hasSelection) {
      return;
    }

    if (selectionType === What.COLLECTIONS) {
      const objects = selection as Collection[];
      const like = !objects.every(({ liked_by_user }) => liked_by_user);
      collectionActions.invoke({
        action: 'bulk_like',
        like,
        uids: objects
          .filter(({ liked_by_user }) => liked_by_user !== like)
          .map(pkify),
      });
    } else if (selectionType === What.MOMENTS) {
      const objects = selection as Moment[];
      const like = !objects.every(({ liked_by_user }) => liked_by_user);
      momentActions.invoke({
        action: 'bulk_like',
        like,
        moments: objects.filter(({ liked_by_user }) => liked_by_user !== like),
      });
    } else if (selectionType === What.GAMES) {
      const objects = selection as Game[];
      const like = !objects.every(({ liked_by_user }) => liked_by_user);
      gameActions.invoke({
        action: 'bulk_like',
        like,
        uids: objects
          .filter(({ liked_by_user }) => liked_by_user !== like)
          .map(pkify),
        uid: null,
      });
    } else if (selectionType === What.PLAYERS) {
      const objects = selection as Player[];
      const like = !objects.every(({ followed_by_user }) => followed_by_user);
      playerActions.invoke({
        action: 'bulk_like',
        like,
        uids: objects
          .filter(({ followed_by_user }) => followed_by_user !== like)
          .map(pkify),
        uid: null,
      });
    }
  }, [
    hasSelection, selectionType, selection,
    collectionActions, momentActions, gameActions, playerActions,
  ]);

  const handleMassDownload = React.useCallback(() => {
    if (selectionType === What.MOMENTS) {
      const objects = selection as Moment[];
      momentActions.invoke({
        action: 'bulk_download',
        moments: objects,
      });
    } else if (selectionType === What.COLLECTIONS) {
      const objects = selection as Collection[];
      collectionActions.invoke({
        action: 'bulk_download',
        uids: objects.map(pkify),
      });
    }
  }, [collectionActions, momentActions, selection, selectionType]);

  const handleMassDelete = React.useCallback(() => {
    if (selectionType === What.MOMENTS) {
      const objects = selection as Moment[];
      momentActions.invoke({
        action: 'bulk_delete',
        moments: objects,
      });
    } else if (selectionType === What.COLLECTIONS) {
      const objects = selection as Collection[];
      collectionActions.invoke({
        action: 'bulk_delete',
        uids: objects.map(pkify),
      });
    }
  }, [collectionActions, momentActions, selection, selectionType]);

  const handleMomentBlock = React.useCallback((item: Moment, block: BlockMoment) => {
    momentActions.invoke({
      action: 'block',
      moment: item,
      block,
    });
  }, [momentActions]);

  const handleCollectionBlock = React.useCallback((item: Collection, block: BlockCollection) => {
    collectionActions.invoke({
      action: 'block',
      uid: item.pk,
      block,
    });
  }, [collectionActions]);

  const handleSelect = React.useCallback((type: T, item?: WhatType<T>): void => {
    let $items = selection;

    if (selectionType !== type) {
      $items = null;
      setSelectionType(type);
    }

    if (item) {
      if (item && $items?.includes(item)) {
        $items = without([item], $items);
      } else if (item && $items && !$items.includes(item)) {
        $items = [...$items, item];
      } else if (item && !$items) {
        $items = [item];
      } else if (!item) {
        $items = null;
      }
    }
    setSelection($items);
  }, [selection, selectionType]);

  const handleMomentPin = React.useCallback((props: PinProps<Moment>) => {
    momentActions.invoke({
      action: 'pin',
      moment: props.item,
      orgId: null,
      pin: {
        orgId: props.orgId,
        pin: null, // intentionally
        what: props.what,
        where: props.where,
      } as PinEntity,
    });
  }, [momentActions]);

  const handleCollectionPin = React.useCallback(
    (props: PinProps<Collection>) => {
      collectionActions.invoke({
        action: 'pin',
        uid: props.item.pk,
        pin: {
          pin: null, // intentionally
          orgId: props.orgId,
          what: props.what,
          where: props.where,
        } as PinEntity,
      });
    },
    [collectionActions],
  );

  const handleMomentLink = React.useCallback(
    (m: Moment, target: HTMLElement) => setLink({
      type: What.MOMENTS,
      item: m,
      target,
    }),
    [],
  );

  const handleCollectionLink = React.useCallback(
    (m: Collection, target: HTMLElement) => setLink({
      type: What.COLLECTIONS,
      item: m,
      target,
    }),
    [],
  );

  const handleGameLink = React.useCallback(
    (g: Game, target: HTMLElement) => setLink({
      type: What.GAMES,
      item: g,
      target,
    }),
    [],
  );

  const handlePlayerLink = React.useCallback(
    (a: Player, target: HTMLElement) => setLink({
      type: What.PLAYERS,
      item: a,
      target,
    }),
    [],
  );

  const handleLinkClose = React.useCallback(() => setLink(null), []);

  const handleShareClose = React.useCallback(() => {
    setShare(null);
    setShares(null);
  }, []);

  const isPlayerOpen = Boolean(momentUid || collectionUid);

  const handleConfirmStaffBaseAction = React.useCallback((remember: boolean): void => {
    setDelayedMomentAction(null);
    momentActions.invoke(delayedMomentAction);
    if (remember) {
      saveSettings({ staff_edit_warning: false });
    }
  }, [delayedMomentAction, momentActions, saveSettings]);

  const handleCancelStaffBaseAction = React.useCallback((): void => {
    setDelayedMomentAction(null);
  }, []);

  const value = React.useMemo<AdminContextType<What>>(() => ({
    isPlayerOpen,
    mode,
    selection,
    selectionType,
    onCollectionBlock: handleCollectionBlock,
    onCollectionClick: handleCollectionClick,
    onCollectionLike: handleCollectionLike,
    onCollectionLink: handleCollectionLink,
    onCollectionMenu: handleOpenCollectionMenu,
    onCollectionPin: handleCollectionPin,
    onGameLike: handleGameLike,
    onGameLink: handleGameLink,
    onLoad: setItems as (item: WhatType<T>[]) => void,
    onMomentBlock: handleMomentBlock,
    onMomentClick: handleMomentClick,
    onMomentLike: handleMomentLike,
    onMomentLink: handleMomentLink,
    onMomentMenu: handleOpenMomentMenu,
    onMomentPin: handleMomentPin,
    onPlayerLike: handlePlayerLike,
    onPlayerLink: handlePlayerLink,
    onSelect: handleSelect,
    popBack,
    pushBack,
    status: {
      moments: momentActions.status,
      collections: collectionActions.status,
    },
  }), [
    mode, isPlayerOpen, selection, selectionType,
    handleMomentClick, handleOpenMomentMenu, handleMomentLike, handleMomentBlock,
    handleCollectionClick, handleOpenCollectionMenu, handleCollectionLike, handleCollectionBlock,
    handleSelect, setItems, handleGameLike, handlePlayerLike, handleMomentPin,
    momentActions.status, collectionActions.status, pushBack, popBack,
    handleCollectionPin, handleCollectionLink, handleMomentLink, handleGameLink, handlePlayerLink,
  ]);

  const uploads = useUploads();

  const active = Boolean(uploads.active);

  React.useEffect(() => {
    if (active) {
      const text = 'One or more videos are uploading, do you want to leave the page?';
      const listener = (e: BeforeUnloadEvent): string => {
        e.preventDefault();
        e.returnValue = text;
        return text;
      };

      window.addEventListener('beforeunload', listener, false);
      return (): void => window.removeEventListener('beforeunload', listener, false);
    }
  }, [active]);

  return (
    <AdminContext.Provider value={value}>
      <div className={s.root}>
        <Menu
          progress={uploads.stats}
          user={user}
        />
        <div className={s.content}>
          {back.length && back[back.length - 1] ? (
            <Link to={back[back.length - 1]} className={s.back}>
              Back
            </Link>
          ) : null}
          <UploadsContext.Provider value={uploads}>
            <React.Suspense fallback={<Loader />}>
              <Switch>
                <Route component={Settings} path={SETTINGS$.route} />
                <Route component={Library} path={LIBRARY$.route} />
                {user.is_staff && !user.is_cms_user && <Staff />}
                {!user.is_staff && user.is_cms_user && <Clients />}
                <Route path="*" component={Page404} />
              </Switch>
            </React.Suspense>
          </UploadsContext.Provider>
        </div>
        <SelectPanel<T>
          deleting={
            momentActions.status.delete.length !== 0
            || collectionActions.status.remove.length !== 0
          }
          items={items}
          onCollection={handleMomentsCollection}
          onDelete={handleMassDelete}
          onDownload={handleMassDownload}
          onLike={handleMassLike}
          onSelect={setSelection}
          selection={selection}
          type={selectionType}
        />
      </div>
      <PopupPlayer
        collectionId={collectionUid}
        disabled={momentActions.status.create.includes(null)
          || (momentActions.status.update.length !== 0)
          || collectionActions.status.clone.includes(null)
          || (collectionUid && collectionActions.status.update.includes(collectionUid))}
        mode={mode}
        momentId={momentUid}
        onCancel={handleResetEdit}
        onClose={handleClosePlayer}
        onCollectionLike={handleCollectionLike}
        onCollectionLink={handleCollectionLink}
        onCollectionMenu={handleOpenCollectionMenu}
        onMomentLike={handleMomentLike}
        onMomentLink={handleMomentLink}
        onMomentMenu={handleOpenMomentMenu}
        onMomentSave={handleMomentSave}
        user={user}
      />
      {collectionActions.modals}
      {momentActions.modals}
      {menu?.type === What.COLLECTIONS && (
        <CollectionActionDropdown
          className={s.dropdown}
          item={menu.item as Collection}
          context={EventContext.ADMIN}
          invoke={collectionActions.invoke}
          onClose={handleCloseMenu}
          showClone={!menu.simple && menu.item.can_clone}
          showDownload={menu.item.can_download}
          showEdit={!menu.simple && menu.item.can_manage}
          showInfo
          showPublish={!menu.simple && user.can_publish_content && !user.is_staff}
          showRemove={!menu.simple && menu.item.can_manage}
          showShare={!user.is_staff}
          showVisibility={user.is_staff}
          status={collectionActions.status}
          target={menu.target}
          user={user}
        />
      )}
      {menu?.type === What.MOMENTS && (
        <MomentActionsDropdown
          className={s.dropdown}
          invoke={momentActions.invoke}
          item={menu.item as Moment}
          onClose={handleCloseMenu}
          showClone={!collectionUid && !menu.simple}
          showCollection
          showDelete={!menu.simple && menu.item.can_manage}
          showDownload={menu.item.can_download}
          showEdit={!menu.simple && menu.item.can_manage}
          showFlag={!menu.simple && user.is_staff}
          showGame={!menu.simple}
          showInfo
          showReview={!menu.simple && user.is_staff}
          showPublish={!menu.simple && user.can_publish_content && !user.is_staff}
          showShare={!user.is_staff}
          showVisibility={user.is_staff}
          status={momentActions.status}
          target={menu.target}
          user={user}
        />
      )}
      {link && (
        <CopyLinkDropdown
          context={EventContext.ADMIN}
          item={link.item}
          onClose={handleLinkClose}
          orgId={user.org?.pk || user.conference?.pk}
          target={link.target}
          type={link.type}
          userId={user?.pk}
        />
      )}
      <ShareSidebar
        items={shares?.items}
        onClose={handleShareClose}
        type={shares?.type}
        user={user}
      />
      <CollectionModal
        uid={(mode === PlayerMode.COLLECTION_EDIT || mode === PlayerMode.COLLECTION_CLONE)
          && collectionUid}
        clone={mode === PlayerMode.COLLECTION_CLONE}
        user={user}
        onClose={handleResetEdit}
        onMomentSave={handleMomentSave}
        onSave={handleCollectionSave}
        onSuccess={handleResetEdit}
        saving={momentActions.status.create.includes(null)
          || (momentActions.status.update.length !== 0)
          || collectionActions.status.clone.includes(null)
          || (collectionUid && collectionActions.status.update.includes(collectionUid))}
      />
      {user.is_staff && (
        <StaffEditWarningModal
          onCancel={handleCancelStaffBaseAction}
          onConfirm={handleConfirmStaffBaseAction}
          show={Boolean(delayedMomentAction)}
        />
      )}
    </AdminContext.Provider>
  );
};
