import { curry } from 'lodash/fp';
import {
  GameAction, Moment, Player, Tag, Team, User,
} from 'weplayed-typescript-api';

import {
  parseAsPlayer, parseAsTag, parseAsTeam, RenderData,
} from 'common/utils/moments';
import { makeTokens } from 'common/utils/strings';

import { FilterControlTags, FilterTag, FilterTagType } from './types';

export const filterMomentsForReview = (moments: Moment[]): Moment[] => moments
  .filter(
    (moment: Moment) => !moment.reviewed && !moment.blocked && !moment.is_nav,
  )
  .sort(
    (a: Moment, b: Moment): number => (
      (b.score ? b.score.score : 0) < (a.score ? a.score.score : 0) ? -1 : 1
    ),
  );

type ExtractMomentNames = 'players' | 'teams' | 'tags';

type ExtractMomentTagsResult = Record<ExtractMomentNames, FilterTag[]>;

type ExtractMomentTagMap = Record<ExtractMomentNames, Record<string, FilterTag>>;

const mapToResult = (map: ExtractMomentTagMap): ExtractMomentTagsResult => (
  Object.fromEntries(Object.entries(map).map(([k, v]) => [k, Object.values(v)]))
) as ExtractMomentTagsResult;

const extractMomentTags = (
  { description }: Moment,
  update?: ExtractMomentTagMap,
): ExtractMomentTagsResult & { text: string } | undefined => {
  let match: RenderData<Player | Tag | Team>;
  let text = description;

  const result = update ?? { teams: {}, tags: {}, players: {} };

  do {
    match = parseAsPlayer(text);
    if (match) {
      if (!result.players[match.id]) {
        result.players[match.id] = {
          color: match.color,
          pk: match.id,
          type: FilterTagType.PLAYER,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  do {
    match = parseAsTeam(text);
    if (match) {
      if (!result.teams[match.id]) {
        result.teams[match.id] = {
          color: match.color,
          pk: match.id,
          type: FilterTagType.TEAM,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  do {
    match = parseAsTag(text);
    if (match) {
      if (!result.tags[match.display]) {
        result.tags[match.display] = {
          type: FilterTagType.TAG,
          value: match.display,
        };
      }
      text = match.prefix + match.postfix;
    }
  } while (match);

  if (!update) {
    return {
      ...mapToResult(result),
      text,
    };
  }
};

export const extractMomentsTags = (
  moments?: Moment[],
): ExtractMomentTagsResult => {
  const update = {
    teams: {},
    tags: {},
    players: {},
  };

  moments?.forEach((moment) => extractMomentTags(moment, update));

  return mapToResult(update);
};

export const testMoment = curry((filters: FilterTag[], profile: User, moment: Moment): boolean => {
  const { tags, players, teams, text } = extractMomentTags(moment);

  for (let x = 0; x < filters.length; x += 1) {
    const filter = filters[x];
    switch (filter.type) {
      case FilterTagType.CONTROL:
        switch (filter.value) {
          case FilterControlTags.REVIEWED:
            if (!moment.reviewed) {
              return false;
            }
            break;

          case FilterControlTags.NOT_REVIEWED:
            if (moment.reviewed) {
              return false;
            }
            break;

          case FilterControlTags.MY:
            if (moment.curator.pk !== profile?.pk) {
              return false;
            }
            break;

          case FilterControlTags.PLAY_BY_PLAY:
            return false;

          default:
            throw new Error('No control present');
        }
        break;

      case FilterTagType.TAG: {
        if (!tags.some((tag) => tag.value === filter.value)) {
          return false;
        }

        break;
      }

      case FilterTagType.PLAYER: {
        if (!players.some((player) => player.pk === filter.pk)) {
          return false;
        }

        break;
      }

      case FilterTagType.TEAM: {
        if (!teams.some((team) => team.pk === filter.pk)) {
          return false;
        }

        break;
      }

      case FilterTagType.TEXT: {
        const word = filter.value.toLocaleLowerCase();
        const tokens = makeTokens(text);

        if (!tokens.some((token) => token.indexOf(word) !== -1)) {
          return false;
        }

        break;
      }

      default:
    }
  }

  return true;
});

export const testPbp = (filters: FilterTag[], { summary }: GameAction): boolean => {
  const tokens = makeTokens(summary);

  return filters.length === 0 || filters
    .map(({ type, value }) => type === FilterTagType.TEXT && value.toLocaleLowerCase())
    .filter(Boolean)
    .some((value) => tokens.some((token) => token.indexOf(value) !== -1));
};
