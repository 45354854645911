import animated from '../../objects/images/animated';
import statics from '../../objects/images/statics';
import {
  AnnotationPrimitivesCategory, ImageComponent, PathImageComponent,
} from '../../types';
import * as ArrowImg from './previews/arrow-simple.png';
import * as SimpleLineImg from './previews/line-simple.png';

const Markers: AnnotationPrimitivesCategory = {
  name: 'Markers',
  preview: (animated.circle as ImageComponent).preview,
  items: [
    {
      annotation: {
        type: 'image',
        start: 0,
        duration: 0,
        x: 0.5,
        y: 0.5,
        size: 0.2,
        path: 'images.animated.circle',
      },
      preview: (animated.circle as ImageComponent).preview,
      name: 'Animated Marker',
    },
    {
      annotation: {
        type: 'image',
        start: 0,
        duration: 0,
        x: 0.5,
        y: 0.5,
        size: 0.2,
        path: 'images.static.circle',
      },
      preview: (statics.circle as ImageComponent).preview,
      name: 'Static Marker',
    },
    {
      annotation: {
        type: 'path',
        start: 0,
        duration: 0,
        points: [{ x: 0.2, y: 0.7 }, { x: 0.5, y: 0.5 }],
        color: '#ff0000',
        arrow: true,
      },
      preview: ArrowImg,
      name: 'Simple Arrow',
    },
    {
      annotation: {
        type: 'path',
        start: 0,
        duration: 0,
        points: [{ x: 0.2, y: 0.7 }, { x: 0.5, y: 0.5 }],
        color: '#ffff00',
      },
      preview: SimpleLineImg,
      name: 'Simple Line',
    },
    {
      annotation: {
        type: 'path-image',
        start: 0,
        duration: 0,
        path: 'images.static.t-line',
        color: '#ffff00',
        x: 0.5,
        y: 0.5,
      },
      preview: (statics['t-line'] as PathImageComponent).preview,
      name: 'T-Line',
    },
  ],
};

export default Markers;
