import * as React from 'react';
import { queryCache, useMutation, useQuery, useQueryCache } from 'react-query';
import {
  GameFull, GamePlayByPlay, games, HttpError, What,
} from 'weplayed-typescript-api';

import { signalIs } from '../useDataSignal';
import { useQueryCacheUpdate } from '../useQueryCacheUpdate';
import {
  QueryCacheUpdateEntityConfigs, QueryCacheUpdater,
} from '../useQueryCacheUpdate/types';
import { UseGameReturnType, UseGameType } from './types';
import { getKey, getPbpKey, injectMomentGame, injectMomentPBP } from './utils';

export const useGame: UseGameType = function useGame({
  cms,
  momentId,
  pbp = true,
  uid,
  videoId,
}) {
  const [pbpKey, setPbpKey] = React.useState<string[] | null>(null);

  const [stoppingLive, setStoppingLive] = React.useState(false);
  const [creatingPlaylists, setCreatingPlaylists] = React.useState(false);

  const cache = useQueryCache();

  const gameKey = React.useMemo(() => getKey(uid, videoId, cms), [cms, uid, videoId]);

  const config = React.useMemo<
    QueryCacheUpdateEntityConfigs
  >(<W extends What>() => {
    const updater: QueryCacheUpdater<W, GameFull> = (game, signal) => {
      if (signalIs.updated(signal, What.MOMENTS) && signal.item.game_id === game?.pk) {
        const moment = signal.item;
        const ngame = injectMomentGame(game, moment);

        if (pbpKey && ngame?.pk === moment.game_id) {
          const pbpData = queryCache.getQueryData<GamePlayByPlay>(pbpKey);

          if (pbpData?.game_id === ngame.pk) {
            queryCache.setQueryData(pbpKey, injectMomentPBP(ngame, pbpData, moment));
          }
        }

        return [ngame, false];
      }

      return [game, true];
    };

    return [{ predicate: gameKey, what: What.GAMES, updater }];
  }, [gameKey, pbpKey]);

  useQueryCacheUpdate(config);

  const onSuccess = React.useCallback((): void => {
    cache.invalidateQueries(gameKey);
  }, [cache, gameKey]);

  const endStream: UseGameReturnType['endStream'] = useMutation(
    async () => {
      setStoppingLive(true);
      await games.endStream({ uid });
    },
    { onSuccess },
  );

  const createPivotalPlaylist: UseGameReturnType['createPivotalPlaylist'] = useMutation(
    async () => {
      setCreatingPlaylists(true);
      await games.createPlaylists({ uid });
    },
    { onSuccess },
  );

  const qa: UseGameReturnType['qa'] = useMutation(
    (status) => games.qa({ uid, status }),
    { onSuccess },
  );

  const game: UseGameReturnType['game'] = useQuery<GameFull, HttpError>(
    gameKey,
    async () => {
      const data = await games.get({ uid, videoId, cms, momentId });

      // data.video.streaming_url = 'https://content.weplayed.nomad-cms.com/live/stas-test-channel-7/stas-test-channel-7.m3u8';
      // data.live_now = true;
      // data.can_create_playlist = true;
      // data.has_play_by_play = false;
      // data.moments = [];
      // data.moment_count = 0;

      setPbpKey(data.has_play_by_play ? getPbpKey(uid, cms) : null);

      if (stoppingLive && !data.live_now) {
        setStoppingLive(false);
      }

      if (creatingPlaylists && !data.can_create_playlist) {
        setCreatingPlaylists(false);
      }

      return data;
    },
    {
      enabled: Boolean(uid),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: (creatingPlaylists || stoppingLive) ? 2000 : undefined,
    },
  );

  const pbpData: UseGameReturnType['pbp'] = useQuery(
    pbpKey,
    () => games.pbp({ uid, cms }),
    {
      keepPreviousData: true,
      enabled: Boolean(pbpKey && pbp),
      refetchOnWindowFocus: false,
    },
  );

  return {
    createPivotalPlaylist,
    endStream,
    game,
    pbp: pbpData,
    qa,
  };
};
