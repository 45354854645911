import cx from 'classnames';
import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { matchPath, NavLink } from 'react-router-dom';
import { What } from 'weplayed-typescript-api';

import { LibraryTypes } from 'common/types';

import {
  CLIENTS$, DISCOVER$, HOME$, LIBRARY$, PUBLISH$, STUDIO$,
} from 'cms/routes';

import * as s from './Menu.m.less';
import { MenuProps } from './types';

const isDiscoverActive = (
  _,
  { pathname },
): boolean => matchPath(pathname, DISCOVER$.route) !== null;

const isLibraryActive = (
  _,
  { pathname },
): boolean => matchPath(pathname, LIBRARY$.route) !== null;

const isClientsActive = (
  _,
  { pathname },
): boolean => matchPath(pathname, CLIENTS$.route) !== null;

export const Menu: React.FC<MenuProps> = function Menu({
  progress,
  user,
}) {
  const digest = React.useMemo(
    () => (progress.digest ? ({ width: `${progress.digest}%` }) : null),
    [progress.digest],
  );
  const upload = React.useMemo(
    () => (progress.upload ? ({ width: `${progress.upload}%` }) : null),
    [progress.upload],
  );

  return (
    <div className={cx(s.root, s.collapsed)}>
      <ul>
        <li className={s.home}>
          <NavLink to={HOME$.buildPath()} activeClassName={s.active} exact>
            <span>Home</span>
          </NavLink>
        </li>
        {user.is_cms_user && (user.can_upload_videos || user.can_manage_capture) && (
          <li className={s.studio}>
            <NavLink
              to={STUDIO$.buildPath()}
              activeClassName={s.active}
            >
              <span>Studio</span>
              {(digest || upload) ? (
                <div className={s.progress}>
                  {progress.digest !== 0 && (
                    <div className={s.digest}>
                      <span style={digest}>{`${progress.digest}%`}</span>
                    </div>
                  )}
                  {progress.upload !== 0 && (
                    <div className={s.upload}>
                      <span style={upload}>{`${progress.upload}%`}</span>
                    </div>
                  )}
                </div>
              ) : null}
              {progress.error ? (
                <Badge pill variant="danger" className={s.badge}>{progress.error}</Badge>
              ) : null}
            </NavLink>
          </li>
        )}
        {user.is_staff && (
          <li className={s.clients}>
            <NavLink
              to={CLIENTS$.buildPath()}
              activeClassName={s.active}
              isActive={isClientsActive}
            >
              <span>Clients</span>
            </NavLink>
          </li>
        )}
        {user.is_cms_user && (
          <li className={s.discover}>
            <NavLink
              to={DISCOVER$.buildPath({ section: What.MOMENTS })}
              activeClassName={s.active}
              isActive={isDiscoverActive}
            >
              <span>Discover</span>
            </NavLink>
          </li>
        )}
        <li className={s.library}>
          <NavLink
            to={LIBRARY$.buildPath({
              type: LibraryTypes.FAVORITES,
              section: What.MOMENTS,
            })}
            isActive={isLibraryActive}
            activeClassName={s.active}
          >
            <span>Library</span>
          </NavLink>
        </li>
        {user.is_cms_user && user.can_publish_content && (
          <li className={s.publish}>
            <NavLink to={PUBLISH$.buildPath()} activeClassName={s.active}>
              <span>Publish</span>
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};
