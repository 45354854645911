import { ReactComponent as Left } from '@mdi/svg/svg/chevron-left.svg';
import { ReactComponent as Right } from '@mdi/svg/svg/chevron-right.svg';
import { ReactComponent as Reset } from '@mdi/svg/svg/contain.svg';
import { ReactComponent as Plus } from '@mdi/svg/svg/plus.svg';
import * as cx from 'classnames';
import { curry, memoize, noop } from 'lodash/fp';
import * as React from 'react';

import { formatDuration } from 'common/utils/time';

import { VideoPreviewBar } from 'cms/components/VideoPreviewBar';

import { Expand, MoveWhat, Props } from './types';
import * as s from './VideoTrimmer.m.less';

const VideoTrimmerWithRef: React.ForwardRefRenderFunction<
HTMLDivElement,
Props
> = function VideoTrimmer({
  position, start, end, children, length, videoUrl,
  step = 0.1, changeBy = 1, minLength = 5, expandBy = 10, disabled,
  onPosition, onChange, onChangeEnd,
}, ref) {
  const containerRef = React.useRef<HTMLDivElement>();
  const spanRef = React.useRef<HTMLDivElement>();
  const timeRef = React.useRef<HTMLDivElement>();
  const positionRef = React.useRef<HTMLDivElement>();
  const insideRef = React.useRef(false);
  const rulerRef = React.useRef<HTMLDivElement>();
  const hoverRef = React.useRef<HTMLDivElement>();
  const [previewTime, setPreviewTime] = React.useState<number>();

  const [moving, setMoving] = React.useState<MoveWhat>();
  const calcX = React.useRef<(x: number) => number>();

  const [min, setMin] = React.useState<number>(Math.max(0, start - changeBy));
  const [max, setMax] = React.useState<number>(Math.min(length, end + changeBy));

  const handleArea: (
    what: Expand,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ) => React.MouseEventHandler = React.useCallback(
    memoize(curry((what: Expand, _e: React.MouseEvent): void => {
      if (what === '-min') {
        setMin(Math.max(0, min - changeBy));
      } else if (what === '+max') {
        setMax(Math.min(length, max + changeBy));
      } else if (what === 'reset') {
        setMax(Math.min(length, end + changeBy));
        setMin(Math.max(0, start - changeBy));
      }
    })),
    [min, max, start, end],
  );

  React.useEffect(() => {
    const listener = (): void => {
      if (['start', 'end'].includes(moving)) {
        onChangeEnd(start, end);
      }

      setMoving(null);
    };

    document.body.addEventListener('mouseup', listener);
    document.body.addEventListener('touchend', listener);

    return (): void => {
      document.body.removeEventListener('mouseup', listener);
      document.body.removeEventListener('touchend', listener);
    };
  }, [onChangeEnd, moving, start, end]);

  const handleMove: (
    e: React.MouseEvent | React.TouchEvent,
    what?: MoveWhat,
  ) => void = React.useCallback(
    (e, what): void => {
      const move = what || moving;

      const x = 'touches' in e ? e.touches[0]?.pageX : e.pageX;
      let pos;

      if (typeof x !== 'number') {
        return;
      }

      if (move === 'position') {
        const { left, right } = timeRef.current.getBoundingClientRect();
        pos = start + ((x - left) / (right - left)) * (end - start);
        onPosition(pos);
      } else if (move === 'start') {
        pos = calcX.current(x);
        if (pos >= min && pos < end - minLength && pos !== start) {
          if (position < pos && pos !== position) {
            onPosition(pos);
          }
          onChange(pos, end);
        }
      } else if (move === 'end') {
        pos = calcX.current(x);
        if (pos <= max && pos > start + minLength && pos !== end) {
          if (position > pos && pos !== position) {
            onPosition(pos);
          }
          onChange(start, pos);
        }
      } else if (insideRef.current) {
        const { left, right } = containerRef.current.getBoundingClientRect();
        pos = min + ((x - left) / (right - left)) * (max - min);
        if (pos >= min && pos <= max) {
          const target = (e.currentTarget) as HTMLElement;
          const { left: offsetLeft } = target.getBoundingClientRect();
          hoverRef.current.style.left = `${x - offsetLeft}px`;
          setPreviewTime(pos);
        } else {
          setPreviewTime(null);
        }
      }
    },
    [moving, onPosition, start, end, min, position, onChange, max, minLength],
  );

  const handleStartMove: (
    what: MoveWhat
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ) => React.MouseEventHandler | React.TouchEventHandler = React.useCallback(
    memoize(curry((what: MoveWhat, e: React.MouseEvent | React.TouchEvent): void => {
      if (
        ('button' in e && e.button !== 0)
        || ('touches' in e && e.touches.length !== 1)
      ) {
        return;
      }

      const x = 'touches' in e ? e.touches[0].pageX : e.pageX;

      setMoving(what);
      setPreviewTime(null);
      if (['start', 'end'].includes(what)) {
        const { left: cl, width: cw } = containerRef.current.getBoundingClientRect();
        const { width: sw } = spanRef.current.getBoundingClientRect();
        const { left: tl } = timeRef.current.getBoundingClientRect();
        const zoom = (max - min) / cw;
        const shiftX = x + cl - tl - (what === 'end' ? sw : 0);

        calcX.current = (left: number): number => (
          Math.round((min + (left - shiftX) * zoom) / step) * step
        );
      }
      handleMove(e, what);
    })),
    [handleMove, min, max, step],
  );

  const handleEnter: React.MouseEventHandler = React.useCallback((e) => {
    insideRef.current = true;
    handleMove(e);
  }, [handleMove]);

  const handleLeave: React.MouseEventHandler = React.useCallback((e) => {
    insideRef.current = false;
    setPreviewTime(null);
    handleMove(e);
  }, [handleMove]);

  const handleExpand: (
    where: Extract<MoveWhat, 'start' | 'end'>,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ) => React.MouseEventHandler = React.useCallback(
    memoize(curry((
      where: Extract<MoveWhat, 'start' | 'end'>,
      _e: React.MouseEvent,
    ) => {
      const st = where === 'start' ? Math.max(0, start - expandBy) : start;
      const en = where === 'end' ? Math.min(length, end + expandBy) : end;

      setMin(Math.max(0, st - changeBy));
      setMax(Math.min(length, en + changeBy));

      onChange(st, en);
      onChangeEnd(st, en);
    })),
    [length, start, end],
  );

  React.useEffect(() => {
    const spanLeft = (100 * ((start - min) / (max - min))).toFixed(2);
    const spanWidth = (100 * ((end - start) / (max - min))).toFixed(2);
    const positionLeft = (
      Math.min(100, Math.max(0, 100 * ((position - start) / (end - start))))
    ).toFixed(2);

    spanRef.current.style.left = `${spanLeft}%`;
    spanRef.current.style.width = `${spanWidth}%`;
    positionRef.current.style.left = `${positionLeft}%`;

    const bgWidth = 100 / (max - min);
    const bgOffset = (min - Math.floor(min)) * bgWidth;
    const bgHeight = window.getComputedStyle(rulerRef.current).backgroundSize.split(' ').pop();
    rulerRef.current.style.backgroundPositionX = `${bgOffset.toFixed(2)}%`;
    rulerRef.current.style.backgroundSize = `${bgWidth.toFixed(2)}% ${bgHeight}`;
  }, [position, start, end, min, max]);

  const expandText = `Expand by ${changeBy}s`;

  const startHandler = handleStartMove('start');
  const endHandler = handleStartMove('end');
  const positionHandler = handleStartMove('position');

  return (
    <div
      className={cx(
        s.root,
        !moving && s.withAnimations,
        typeof previewTime === 'number' && s.withPreview,
        disabled && s.disabled,
      )}
      ref={ref}
    >
      <button
        className={cx('fullstory-ignore', s.start)}
        disabled={min === 0}
        onClick={handleArea('-min')}
        title={expandText}
        type="button"
      >
        <Left />
      </button>
      <div
        className={s.container}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onMouseMove={handleMove}
        onTouchMove={handleMove}
        ref={containerRef}
      >
        <div className={s.ruler} ref={rulerRef} />
        {videoUrl && (
          <VideoPreviewBar
            className={s.preview}
            end={max}
            position={previewTime}
            start={min}
            videoUrl={videoUrl}
          />
        )}
        <div className={s.span} ref={spanRef}>
          <div className={s.inner}>
            <div className={s.controls}>
              <div
                aria-label="Start"
                aria-valuenow={start}
                className={s.handle}
                onKeyPress={noop}
                onTouchStart={startHandler as React.TouchEventHandler}
                onMouseDown={startHandler as React.MouseEventHandler}
                role="slider"
                tabIndex={0}
              />
              <button
                onClick={handleExpand('start')}
                title={`Expand left by ${expandBy}s`}
                type="button"
              >
                <Plus />
                <span>{`${expandBy}s`}</span>
              </button>
            </div>
            <div
              aria-valuenow={position}
              className={s.time}
              onKeyPress={noop}
              onTouchStart={positionHandler as React.TouchEventHandler}
              onMouseDown={positionHandler as React.MouseEventHandler}
              ref={timeRef}
              role="slider"
              tabIndex={0}
            >
              <span aria-label="Moment length">
                {formatDuration(end - start, { fraction: 1 })}
              </span>
            </div>
            {children && <div className={s.children}>{children}</div>}
            <div className={s.times}>
              <span aria-label="Moment start time">
                {formatDuration(start, { fraction: 1 })}
              </span>
              <span aria-label="Moment end time">
                {formatDuration(end, { fraction: 1 })}
              </span>
            </div>
            <div className={s.position} ref={positionRef} />
            <div className={s.controls}>
              <button
                onClick={handleExpand('end')}
                title={`Expand right by ${expandBy}s`}
                type="button"
              >
                <Plus />
                <span>{`${expandBy}s`}</span>
              </button>
              <div
                aria-label="End"
                aria-valuenow={end}
                className={s.handle}
                onKeyPress={noop}
                onTouchStart={endHandler as React.TouchEventHandler}
                onMouseDown={endHandler as React.MouseEventHandler}
                role="slider"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
        <div className={s.hover} ref={hoverRef} />
      </div>
      <button
        className={cx('fullstory-ignore', s.end)}
        disabled={max === length}
        onClick={handleArea('+max')}
        title={expandText}
        type="button"
      >
        <Right />
      </button>
      <button
        className="fullstory-ignore"
        disabled={min === Math.max(0, start - changeBy) && max === Math.min(length, end + changeBy)}
        onClick={handleArea('reset')}
        title="Reset timeline size"
        type="button"
      >
        <Reset />
      </button>
    </div>
  );
};

export const VideoTrimmer: React.FC<Props> = React.forwardRef(VideoTrimmerWithRef);
