// extracted by mini-css-extract-plugin
export var backgroundSpecialGradient = "_8b3da1cfae69b5c9526021799732c85c-less";
export var backgroundGrayGradient = "_3098deb57b2fce76aa48bb09eb4516be-less";
export var tileImage = "_29e46f4cb3cabe27b1506efb3e2833ad-less";
export var small = "d8c39d87e21bd8e1bc6aa5b8f9f4745c-less";
export var root = "_38a952123693e22ad547c0d8491f5303-less";
export var slider = "ae034731660bcee22e13d074354d9949-less";
export var hidden = "_841296a8872b823f1a73141218d0e857-less";
export var disabled = "_33706218d6f4154fa6c9bf6acf5d3cdb-less";
export var title = "_2bc6c06fae8451ba2471189a343ca2d6-less";
export var titleText = "_9b25edf2721863203b214709b32837bd-less";
export var help = "_18d26e93aa80d7d6cae5641d43820b0d-less";
export var body = "_70bc231bf3c5610202d17c36022edb4b-less";
export var backdrop = "_615e03939a2e769cb77643b765036905-less";
export var active = "_2867203a71f486f0a211a267edd4a510-less";
export var close = "_6b73e96bd8f6a7d2808484e5c9f8c441-less";
export var section = "b5236be2d33a56912a5c2cf1fbdbf45c-less";
export var noBorder = "f52d27cb5b2fad3c8f2afaed0ebc5905-less";
export var rest = "_5ce44629fac72759c103a61734c3c4f0-less";
export var buttons = "_9e53f7de9b046d6496e5ed644647d6fc-less";
export var fadeIn = "_618e8df36be01b31b2e187bfd624cf3f-less";
export var spin = "_2f640082b4d58a8e676870b19a9025ed-less";